<template>
  <div class="Tickets">
    <el-tabs type="border-card">
      <el-tab-pane label="未退票信息"><Adult /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import Adult from "@/components/Admin/items/Tickets/Adult";
export default {
  name: "Tickets",
  components: {
    Adult,
  },
  
};
</script>

<style scoped>
.el-tabs {
  height: 100vh;
  overflow-y: auto;
}
</style>
