import { render, staticRenderFns } from "./Yunbo.vue?vue&type=template&id=5f0692be&scoped=true&"
import script from "./Yunbo.vue?vue&type=script&lang=js&"
export * from "./Yunbo.vue?vue&type=script&lang=js&"
import style0 from "./Yunbo.vue?vue&type=style&index=0&id=5f0692be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f0692be",
  null
  
)

export default component.exports