<template>
  <div class="Article">
    <span class="title"
      >三叠纪的一个转折
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      维多利亚时代的古生物学家曾观察到在二叠纪和三叠纪岩层间化石种类的急剧变化。事实上，这确实是划分古生代（“古代生命”）和中生代（“中期生命”）的基础。人们经历了很长时间，才对已知的化石记录进行了一次实质性的数据库汇编，杰克-塞普科斯基（Jack Sepkoski）据此提出了著名的观点，认为二叠纪三叠纪交接时全球生命多样化的重大转变。1984年，他指出这是一个危机的时间点，在这个点上，演化被改写了。同年，Leigh Van Valen也独立提出了这个观点。
    </p>
    <p class="p">
      两位作者在追溯过去的5.4亿年时，不约而同地将二叠纪-三叠纪界线作为一个唯一的拐点，从那时起，海洋生物发生了重大转变。Sepkoski将古生代动物群与现代动物群区分开来，使用术语“进化动物群（Evolutionary Fauna）”作为一个全球范围内地、跨越数亿年的大进化术语（图2A）。古生代的动物群包括腕足类（brachiopods）、皱褶珊瑚及板状珊瑚（rugose and tabulate corals）、头足类（cephalopods）、海百合（crinoids）、海星类（starfish）、介形虫类（ostracods）及笔石类（graptolites），而现代的动物群则包括双壳类（bivalves）、腹足类（gastropods）、软甲纲（malacostracans）、海胆（echinoids）、硬骨鱼类（bony fishes）、鲨鱼（sharks）及海洋四足动物（marine tetrapods）。
    </p>
    <img src="./images/图片4.png" alt="" class="img">
    <p class="p">
      虽然陆生生物还没有做过同样的分析，但PTME也是主导陆地动植物大规模变化的标志（图2B）。在四足动物中，古生代晚期的离片椎类（temnospondyls）、副爬行类（parareptiles）和下孔类（synapsids）让位给了滑体两栖类（lissamphibians）、龟（turtles）、鳞龙形类（lepidosaurs，蜥蜴及其近缘种类）、和初龙型类（archosaurs，鳄鱼、恐龙、鸟及其近缘种类）和哺乳动物（mammals）。在鱼类中，晚古生代的软骨鱼（hybodont sharks）和其他鱼类在三叠纪被新软骨鱼类（neoselachinans）所取代，具厚鳞的软骨鱼基干类群被新鳍鱼类（neopterygian）和再晚一点地真骨鱼类（teleosts）所取代。在昆虫中，重要的现生类群，如双翅目（苍蝇）、毛翅目（蝶类）、鳞翅目（蝴蝶和蛾类）和膜翅目（蜜蜂、黄蜂和蚂蚁）都出现在三叠纪晚期，鞘翅目（甲虫）和脉翅目（草蛉）在这个时期出现了大幅度的扩张。这些构成了现代生物多样性绝大部分的关键昆虫类群，其起源均可以追溯到三叠纪。
    </p>
    <img src="./images/图片5.png" alt="" class="img">
    <p class="p">这一时期的植物分化尚不清楚。似乎大多数主要的植物群在PTME期间都存活了下来，新晋的种子蕨类和针叶植物在三叠纪得到了扩张。而真正的向现代植物群的重要过渡却发生在白垩纪，有花植物，也就是被子植物的出现。这些植物是白垩纪陆地革命的一个关键驱动力，当时吃被子植物并为其授粉的昆虫种类大大增加，而它们的捕食者，蜘蛛、蜥蜴、鸟类和哺乳动物也相应的增长了。</p>
  </div>
</template>

<script>
export default {
    name:'Triassic_page4'
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img{
    height: 400px;
}
</style>