<template>
  <div class="Statement"> <span class="title"
      >责任声明
      <img src="../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">1.本项目仅为创新创业训练学习使用，不以任何形式商用。项目主要用于技术实践、交流、学习，对所学知识加以实际应用，解决现实问题，以提升学生的能力。</p>
    <p class="p">2.项目内用到的素材，主要来自购买和团队自身设计，部分源于网络共享资源，如有侵权，可联系删除网站负责人删除。</p>
    <p class="p">3.项目负责人对恐龙世界很感兴趣，项目灵感产生于游览重庆市自然博物馆，但项目内的数据与重庆自然博物馆无关，如用户对重庆自然博物馆感兴趣，建议实地游玩。</p>
    <p class="p">4.项目内订票功能及文物上报功能同样是为学习技术而做，产生的数据，如：游票二维码，彩信都不能用作现实用途，仅供学习交流。</p>
    <p class="p">5.如有其它问题，可联系网站负责人，QQ：754864892</p>
    </div>
</template>

<script>
export default {
  name: "Statement",
  
};
</script>

<style scoped>
.Statement {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
</style>
