<template>
  <div class="Home">
    <div class="head">
      <transition
        name="animate__animated animate__bounce"
        appear
        enter-active-class="animate__slideInDown"
      >
        <Header />
      </transition>
    </div>
    <Block1 />
    <Lunbotu />
    <Footer />
  </div>
</template>

<script>
//引入导航栏
import Header from "@/components/common/Header";
import Block1 from "@/components/Home/Block1";
import Lunbotu from "@/components/Home/Lunbotu";
import Footer from "@/components/common/Footer";
export default {
  name: "Home",
  components: { Header, Block1, Lunbotu, Footer },
};
</script>

<style scoped>
.Home {
  position: relative;
  z-index: -98;
}
.head {
  width: 100%;
  height: 100vh;
  background-image: url(../images/background/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  z-index: -2;
}
</style>
