<template>
  <div class="CretaceousArchives">白垩纪档案</div>
</template>

<script>
export default {

}
</script>

<style>

</style>