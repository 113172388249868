<template>
  <div class="nav">
    <div class="navt">
      <img src="../../images/logos/logo.png" alt="" class="logo" />
      <a class="time" @click="dialogFormVisible = true"
        ><img src="../../images/icons/time.png" alt="" /><span
          >开馆时间</span
        ></a
      >
    </div>
    <div class="navb">
      <a href="javascript:;" @click="goRoute('Home')">
        <div>首页</div>
        <div style="color: white">Home</div>
      </a>
      <a href="javascript:;" @click="goRoute('Yunbo')">
        <div>云博概况</div>
        <div>Overview of Yunbo</div>
      </a>
      <a href="javascript:;" @click="goRoute('Cloudcollections')">
        <div>云馆藏</div>
        <div>Cloud collections</div>
      </a>
      <a href="javascript:;" @click="goRoute('Ticket')">
        <div>门票预约</div>
        <div>Ticket reservations</div>
      </a>
      <a href="javascript:;" @click="goRoute('Declare')">
        <div>文物申报</div>
        <div>Declaration of cultural relics</div>
      </a>
      <a href="javascript:;" @click="goRoute('CenturyArchives')">
        <div>世纪档案</div>
        <div>Century Archives</div>
      </a>
    </div>
    <el-dialog
      title="开馆时间"
      center
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
      width="500px"
    >
      <div>开放日期：每周二至周日开放 周一例行闭馆(节假日除外)</div>
      <div>入馆时间：09:00-17:00 16:00后停止入馆</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      dialogFormVisible: false,
    };
  },
  methods: {
    goRoute(name) {
      if (this.$route.name == name) {
        window.location.reload();
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  height: 120px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 55;
}

.navt,
.navb {
  max-width: 1400px;
  height: 50%;
  margin: 0 auto;
  display: flex;
}
.navb {
  border-top: 1px solid #666;
}

.navt {
  align-items: center;
}

.navt .logo {
  height: 90%;
}

.navt .time {
  margin-left: auto;
  height: 30px;
  width: 120px;
  background: linear-gradient(to right, #00bdf4, #1982e4);
  border-radius: 40px;
  border-top-right-radius: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navt .time:hover {
  background-color: #08aeea;
  background-image: linear-gradient(90deg, #08aeea 0%, #2af598 100%);
}

.navt .time img {
  height: 20px;
  width: 20px;
  margin: 0px 10px;
}

.navt .time span {
  color: white;
  font-size: 80%;
}

.time {
  margin-left: 30px;
}

.navb a {
  width: 17%;
  padding: 4px 10px;
  position: relative;
}

.navb a::before {
  content: "";
  width: 0%;
  height: 100%;
  background-color: #00b9f2;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: 0.2s;
  z-index: -2;
}

.navb a:hover::before {
  width: 100%;
}

.navb a div {
  margin: 2px 0px;
}

.navb a div:nth-child(1) {
  color: #fff;
  font-size: 100%;
}

.navb a div:nth-child(2) {
  color: #fff;
  font-size: 80%;
}
</style>
