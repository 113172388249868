<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
    style="width: 600px"
  >
    <el-form-item
      label="游客信息"
      v-for="(visitor, index) in ruleForm.visitors"
      :key="index"
    >
      <el-col :span="10">
        <el-form-item>
          <el-input
            v-model="ruleForm.visitors[index].name"
            placeholder="请输入游客姓名"
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12" :offset="2">
        <el-form-item
          ><el-input
            v-model="ruleForm.visitors[index].userID"
            placeholder="请输入游客身份证号"
            maxlength="18"
            show-word-limit
          ></el-input
        ></el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="">
      <el-button type="primary" @click="addNew" size="mini">新增</el-button>
    </el-form-item>
    <el-form-item label="游客电话">
      <el-col :span="10">
        <el-form-item prop="telPhone" required
          ><el-input
            v-model="ruleForm.telPhone"
            maxlength="11"
            show-word-limit
            placeholder="请输入游客电话"
          ></el-input
        ></el-form-item>
      </el-col>
      <el-col :span="6" :offset="2">
        <el-form-item prop="VerificationCode" required>
          <el-input
            v-model="ruleForm.VerificationCode"
            maxlength="5"
            show-word-limit
            placeholder="验证码"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4" :offset="1"
        ><el-button type="primary" @click="sendVerificationCode"
          >获取验证码</el-button
        ></el-col
      >
    </el-form-item>
    <el-form-item label="票数" prop="ticketNum">
      <el-tag style="width: 100px; text-align: center;" type="success">{{ ruleForm.visitors.length }}</el-tag>
    </el-form-item>

    <el-form-item label="参观时间">
      <el-col :span="11">
        <el-form-item prop="time" required>
          <el-date-picker
            v-model="ruleForm.time"
            align="right"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-form-item>

    <el-form-item label="场次" prop="session" required>
      <el-radio-group v-model="ruleForm.session">
        <el-radio :label="0">09:00-12:00</el-radio>
        <el-radio :label="1">12:00-16:30</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')"
        >立即预约</el-button
      >
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { sendVerificationCode, bookTickets } from "@/api/api";
import { Message } from "element-ui";
export default {
  name: "AdultTicket",
  data() {
    return {
      ruleForm: {
        visitors: [
          {
            name: "", //游客姓名
            userID: "",
          },
        ],
        telPhone: "", //游客电话
        ticketNum: "", //票数
        time: "", //日期
        session: "", //场次
        VerificationCode: "", //验证码
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入儿童姓名",
            trigger: "blur",
          },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        telPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            min: 6,
            max: 11,
            message: "长度在 6 到 11 个数字",
            trigger: "blur",
          },
        ],
        VerificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            min: 5,
            max: 5,
            message: "长度6个字符",
            trigger: "blur",
          },
        ],
        userID: [
          { required: true, message: "请输入游客身份证号", trigger: "blur" },
          {
            min: 18,
            max: 18,
            message: "长度在18个字符",
            trigger: "blur",
          },
        ],
        time: [{ required: true, message: "请选择日期", trigger: "blur" }],
        session: [{ required: true, message: "必需选择场次", trigger: "blur" }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "明天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周后",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const dataObj = {
            telPhone: this.ruleForm.telPhone,
            VerificationCode: this.ruleForm.VerificationCode,
            time: this.ruleForm.time,
            session: this.ruleForm.session,
            visitors: this.ruleForm.visitors,
          };

          let result = await bookTickets(dataObj);
          if (result.status == 200) {
            Message.success({
              message: "订票成功！",
            });
            this.$refs[formName].resetFields();
            this.ruleForm.visitors = [
              {
                name: "", //游客姓名
                userID: "",
              },
            ];
          }
          console.log("w", result);
          return true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    addNew() {
      this.ruleForm.visitors.push({
        name: "",
        userID: "",
      });
    },
    //发送验证码
    async sendVerificationCode() {
      let result = await sendVerificationCode(this.ruleForm.telPhone);
      console.log(result);
    },
  },
};
</script>

<style></style>
