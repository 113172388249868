<template>
  <div class="DeveloperCradle">
    <span class="title"
      >开发者摇篮
      <img src="../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">重庆工程学院是一所以工学为主的全日制普通本科高校，位于重庆市。学校创立于2001年，前身是重庆正大软件专修学院。经过多年的发展和成长，学校于2014年正式升格为普通本科院校，更名为重庆工程学院。学校的校训是“尽责、守信、求精、创新”。</p>
    <p class="p">学校设有南泉和双桥两个校区，占地815亩，校舍建筑面积达到38万平方米。学校拥有138个基础实验室和专业实验室、15个科技活动与创新中心以及270个校外实践教学基地，为学生提供良好的学习和实践平台。</p>
    <p class="p">重庆工程学院以应用型高级专门人才培养为目标，具有较强的师资力量。截至2023年6月，学校的专任教师中，副高级及以上职称的教师占比为34.74%，具有硕士学位或博士学位的教师占比为85.71%。学校注重质量教育和实践教学，建设了多个实验教学中心和实践教学基地，为学生提供充实的学习环境和实践机会。</p>
    <p class="p">学校在人才培养方面取得了多项成绩。作为国家人才培养基地，学校设立了全国计算机应用与软件技术专业领域紧缺型人才培养基地、全国物联网技术应用人才培养基地和全国动漫游戏产业人才培养基地。同时，学校还是重庆市特色专业软件工程的培养基地，以及重庆市本科高校一流专业立项建设项目物联网工程专业的培养单位。此外，学校还开设了多门优秀在线开放课程，如《Web程序设计（java）》和《无线传感器网》等。</p>
    <p class="p">重庆工程学院注重创新创业教育，学校设立了创新创业园（重工创谷），成为重庆市科技企业孵化器和大学生创业示范基地。学校与企业开展校企共建的培训项目，其中包括“网红学院”，旨在解决学生就业问题。但应注意的是，“网红学院”并非独立学院或具体专业，教育专家也提醒学生需保持基本的价值判断能力，避免陷入误区。</p>
    <p class="p">重庆工程学院在人才培养、师资力量和实践教学方面取得了显著成绩，在重庆市乃至全国具有较高的知名度和影响力。学校将继续致力于培养高素质应用型人才，促进社会经济的发展和进步。</p>

  </div>
</template>

<script>
export default {
name:'DeveloperCradle'
}
</script>

<style scoped>
.DeveloperCradle {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
</style>