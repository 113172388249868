<template>
  <div class="Article">
    <span class="title"
      >白垩纪亚层
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      　早期的科学文献将白垩纪分为三个时期，依年代早晚为：纽康姆世（Neocomian）、高卢世（Gallic）、森诺世（Senonian）。划出一个中统，至于中统的顶底界如何确定，尚有不同意见。1974～1982年，国际上有一个“中白垩事件”组织，活动非常积极，主要是研究白垩纪期的生物地层学、海侵海退、缺氧事件、地磁场倒转、盐类和白垩的形成、南大西洋和莫桑比克峡谷的开裂，以及印度板块从冈瓦纳古陆的分离等课题。白垩纪中期不是一个确切的地质时代概念，有人从阿普特期算起到桑顿期，多数人则从阿尔布期算起到康尼亚克期。中白垩统的划分除中东地区外，国际上很少使用。白垩系二分法已沿用了几十年，见于大量的出版物及地质图件上，当前不宜于再改动。
    </p>
    <p class="p">
      白垩系的划分以欧洲海相地层为依据，最初以菊石为标准分6～7个阶（期），后来将某些亚阶升级，增加到12个阶（期）,但仍有人习惯于把下白垩统下部的4个阶合称为尼欧可木阶（或译纽康姆阶）。上白垩统中部的康尼亚克、桑顿和坎潘3个阶合称为森诺阶。在这12个阶中可划分出53个菊石带，又以浮游有孔虫和钙质超微化石做为白垩系分阶、分带以及洲际对比的重要依据。
    </p>
    <p class="p">
      目前的科学文献一般将白垩纪分为晚、早两世，共计12期，都以欧洲的地层为名，从最晚到最早细分如下：
    </p>
    <p class="p">
      晚白垩世
    </p>
    <p class="p">
      马斯特里赫特期：70.6 ± 0.6 – 65.8 ± 0.3百万年
    </p>
    <p class="p">
      坎帕期：83.5 ± 0.7 – 70.6 ± 0.6百万年
    </p>
    <p class="p">桑托期：85.8 ± 0.7 – 83.5 ± 0.7百万年</p>
    <p class="p">科尼亚克期：89.3 ± 1.0 – 85.8 ± 0.7百万年</p>
    <p class="p">土仑期：93.5 ± 0.8 – 89.3 ± 1.0百万年</p>
    <p class="p">森诺曼期：99.6 ± 0.9 – 93.5 ± 0.8百万年</p>
    <p class="p">早白垩世</p>
    <p class="p">阿尔布期：112.0 ± 1.0 – 99.6 ± 0.9百万年</p>
    <p class="p">阿普第期：125.0 ± 1.0 – 112.0 ± 1.0百万年</p>
    <p class="p">巴列姆期：130.0 ± 1.5 – 125.0 ± 1.0百万年</p>
    <p class="p">豪特里维期：136.4 ± 2.0 – 130.0 ± 1.5百万年</p>
    <p class="p">凡蓝今期：140.2 ± 3.0 – 136.4 ± 2.0百万年</p>
    <p class="p">贝里亚期：145.5 ± 4.0 – 140.2 ± 3.0百万年</p>
  </div>
</template>

<script>
export default {
  name: "Cretaceous_page2",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
