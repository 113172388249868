<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "App",
  components: {
  },
};
</script>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
}
body,html,#app{
    height: 100%;
    position: relative;
    z-index: -100;
}
body{
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background-color: #ccc;
}
body::-webkit-scrollbar-thumb {
  background-color: #409eff;
  border-radius: 4px;
}
</style>
