<template>
  <div class="DeveloperTeam">
    <span class="title"
      >开发者团队
      <img src="../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">本项目由一群对技术充满热爱的好伙伴开发，团队内成员拥有不同的专业技能和扎实的学术背景。我们采用前后端分离的方式进行开发，以确保项目的高效性和可扩展性。</p>
    <p class="p">团队内有两名擅长前端开发的小伙伴—蒋书衔、马明星，主要的技术栈为HTML+CSS+JavaScript+Vue，其中，蒋书衔同学对页面开发，3D呈现有自己独到的见解，几乎以一己之力在专业第二课堂获得三等奖，专业知识扎实；马明星同学，不仅熟练前端界面开发，同时对后端也很了解，在项目中有着良好的衔接作用，他不仅专业知识扎实，学业成绩也名列前茅，曾获优秀学生奖学金，也在多个竞赛中获奖。</p>
    <p class="p">两名精耕于后端开发的小伙伴—邹光义、张健康，主要的技术栈为C#+.NetCore+EFCore+ASP.NetCore+MySql，其中，邹光义同学对于算法优化有丰厚的经验，同时擅长于数据库的设计与管理，曾获优秀学生奖学金，在多个算法竞赛中都斩获佳绩；张健康同学，擅长于接口开发，对于框架设计有着很深刻的见解，善于多种框架的应用，以及设计RESTful的接口，曾获励志奖学金，在多类竞赛中都小有佳绩。</p>
    <p class="p">作为一个技术团队，我们注重团队协作和沟通，每个成员都能够积极参与讨论、分享经验和解决问题。我们相信通过团队的共同努力，我们能够开发出一款高质量的应用程序，为用户带来极佳的体验。</p>
    <p class="p">团队内秉承着“以兴趣驱动为主，以技术应用为辅”的理念，将所学技术应用于实践，在实践中学习，不断提升自己专业技术水平。本项目由团队共同提出，团队内一致认为，以该项目作为创新训练能大大提高我们的知识应用能力，增加我们对于技术掌握的熟练度。</p>

  </div>
</template>

<script>
export default {
  name: "DeveloperTeam",
};
</script>

<style scoped>
.DeveloperTeam {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
</style>