<template>
  <div class="Article">
    <span class="title"
      >白垩纪的物种分布
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      　白垩纪早期陆地上的裸子植物和蕨类植物仍占统治地位，松柏、苏铁、银杏、真蕨及有节类组成主要植物群。被子植物开始出现于白垩纪早期，中期大量增加，到晚期在陆生植物中居统治地位，山毛榉、榕树、木兰、枫、栎、杨、樟、胡桃、悬铃木等都已出现，接近新生代植物群的面貌。从侏罗纪开始出现的超微化石，其特点随产生层位不同而变化，具有重要的地层学意义，其中除颗石外，还有已经绝灭的微锥石（Nannoconus）、楔形石（Sphenolithus）等。
    </p>
    <p class="p">
      脊椎动物中爬行类从极盛走向衰落，主要代表有暴龙（霸王龙）、古魔翼龙、青岛龙等。侏罗纪以前的硬鳞鱼被真骨鱼所代替。海洋无脊椎动物中浮游有孔虫异军突起，成为划分对比白垩纪中、晚期海相地层的重要依据，底栖大型有孔虫中也出现了许多标准化石。菊石和箭石演化迅速而明显，分布广泛，是传统的划分阶和带的标准化石。群生底栖的固着蛤类（Rudistids）可形成礁体,为典型的暖水动物群，在中华人民共和国西藏和南疆上白垩纪地层中均有发现。海胆在特提斯海中颇为繁盛，有少数标准种属。珊瑚和腕足动物在白垩纪居于次要地位。淡水无脊椎动物也很丰富，如甲壳类的介形虫和叶肢介演化迅速，软体动物中的螺和蚌分布广泛，还有昆虫与淡水轮藻化石，它们中的许多种属都可以成为划分对比陆相地层的标准化石，在地质填图、石油和煤等矿产资源勘探中起重要作用。
    </p>
    <p class="p">
      中国早白垩世的植物地理分区非常明显，根据对孢子花粉的研究，境内至少存在两个明显差异的孢粉植物群。
    </p>
    <p class="p">
      ①无缝双囊粉类-无突肋纹孢（Disacciatrileti-Cicatricosisporites）植物群。分布于北方区,主要包括东北和华北北部地区。这里植物繁茂，尤其是松科、罗汉松科和海金砂科等植物，形成丰富的煤藏。也说明这里是温暖潮湿气候带。
    </p>
    <p class="p">
      ②克拉梭粉-莎草蕨孢（Clas-sopollis-Schizaeoisporites）植物群。分布于南方区,多产出于含膏盐沉积的红色地层，反映出当时较为炎热而干旱的气候环境。在北纬40°～50°之间，存在着一个呈北西西-南东东方向的条状过渡带,南北植物群发生混生现象。从全球分布来看，中华人民共和国南、北两个植物区与干旱带和湿亚热带的情况相当。
    </p>
    <p class="p">
      植物
    </p>
    <p class="p">白垩纪早期，以裸子植物为主的植物群落仍然繁茂，而被子植物的出现则是植物进化史中的又一次重要事件。白垩纪有了可靠的早期被子植物，到晚白垩纪晚期被子植物迅速兴盛，代替了裸子植物的优势地位，形成延续至今的被子植物群，诸如木兰、柳、枫、白杨、桦、棕榈等，遍布地表。被子植物的出现和发展，不仅是植物界的一次大变革，同时也给动物以极大的影响。被子植物为某些动物，如昆虫、鸟类、哺乳类，提供了大量的食料，使它们得以繁育；从另一方面看，动物传播花粉与散布种子的作用，同样也助长了被子植物的繁茂和发展。</p>
    <p class="p">开花植物（被子植物）在白垩纪开始出现、散布，但直到坎潘阶才成为优势植物。蜜蜂的出现，有助于开花植物的演化；开花植物与昆虫是共同演化的实例。榕树、悬铃木、木兰花等大型植物开始出现。一些早期的裸子植物仍继续存在，例如松柏目。南洋杉与其他松柏繁盛并分布广泛，而本内苏铁目在白垩纪末灭亡。</p>
    <p class="p">早白垩世仍以裸子植物中的苏铁类、本内苏铁类、银杏类和松柏类为主，真蕨类仍然繁盛。从早白垩世晚期兴起的地被子植物到晚白垩世得到迅速发展，逐渐取代了裸子植物而居统治地位。本纪初期出现了被子植物，以后逐步发展。菊石和恐龙、翼龙、鱼龙、蛇颈龙等则由繁盛逐步趋于绝灭，哺乳类和鸟类成为新兴地动物类群。</p>
    <p class="p">陆栖动物</p>
    <p class="p">白垩纪恐龙种类达到极盛，这时候最著名的恐龙是霸王龙，而当时海洋中巨大凶猛的爬行动物并不亚于霸王龙，其中混龙类的上龙和海生蜥蜴类的沧龙身长可超过15米，比我们认识的逆戟鲸和大白鲨都大。白垩纪海洋中造礁的厚壳蛤达到极盛，一度取代珊瑚成为主要的造礁生物，使现代类型的珊瑚礁中断了将近七千万年。到大约六千七百万年前白垩纪结束时，这些海洋和陆地上的动物大量灭绝，只有少量残存在。 [3] </p>
    <p class="p">中生代许多盛行和占优势的门类，如裸子植物、爬行动物、菊石和箭石等，后期相继衰落或绝灭，新兴的被子植物、鸟类、哺乳动物及腹足类、双壳类等有所发展，预示着新的生物演化阶段新生代的来临。脊椎动物中的爬行类从晚侏罗世至早白垩世达到极盛，代表有暴龙、翼龙、青岛龙等，随后走向衰落。白垩纪末，恐龙、菊石和其他生物类群大量绝灭。引起这次生物大规模绝灭的原因，有许多争论。有人认为是宇宙中的一颗巨大流星体撞击地球所致，其依据是在白垩系和第三系界线上粘土岩中铱元素含量异常高。</p>
    <img src="./images/图片1.jpg" alt="" class="img">
    <p class="p">动物界里，哺乳动物还是比较小，只是陆地动物的一小部分。陆地的优势动物仍是主龙类爬行动物，尤其是恐龙，它们较之前一个时期更为多样化。翼龙目繁盛于白垩纪中到晚期，但它们逐渐面对鸟类辐射适应的竞争。在白垩纪末期，翼龙目仅存两个科左右。鸟类是脊椎动物向空中发展取得最大成功的类群。白垩纪早期鸟类开始分化，并且飞行能力及树栖能力比始祖鸟大大提高。我国古生物学家发现的著名的“孔子鸟”就是早白垩世鸟类的代表分子。 [4] </p>
    <p class="p">白垩纪末，地球上的生物经历了又一次重大的灭绝事件：在地表居统治地位的爬行动物大量消失，恐龙完全灭绝；一半以上的植物和其他陆生动物也同时消失。究竟是什么原因导致恐龙和大批生物突然灭绝？这个问题始终是地质历史中的一个难解之谜。普遍被大家接受的观点是陨石撞击说。引人注目的是，哺乳动物是这次灭绝事件的最大受益者，它们度过了这场危机，并在随后的新生代占领了由恐龙等爬行动物退出的生态环境，迅速进化发展为地球上新的统治者。中国辽宁省的炒米店子组发现了大量的白垩纪早期小型恐龙、鸟类、以及哺乳类。这里发现的多种手盗龙类，被视为恐龙与鸟类间的连结，其中包含数种有羽毛恐龙。</p>
    <p class="p">昆虫在这个时期开始多样化，并发现最古老的蚂蚁、白蚁、鳞翅目（蝴蝶与蛾）。芽虫、草蜢、瘿蜂也开始出现。</p>
    <p class="p">海生动物</p>
    <p class="p">海洋里，我们认识的鳐鱼、鲨鱼和其他硬骨鱼也常见了。海生爬行动物则包含：生存于早至中期的鱼龙类、早至晚期的蛇颈龙类、白垩纪晚期的沧龙类。</p>
    <p class="p">杆菊石具有笔直的甲壳，属于菊石亚纲，与造礁生物厚壳蛤同为海洋的繁盛动物。黄昏鸟目是群无法飞行的原始鸟类，可以在水中游泳，如同现代䴙鷉。有孔虫门的球截虫科（Globotruncanidae）与棘皮动物（例如海胆、海星）继续存活。在白垩纪，海洋中的最早硅藻（应为硅质硅藻，而非钙质硅藻）出现；生存于淡水的硅藻直到中新世才出现。对于造成生物侵蚀的海洋物种，白垩纪是这些物种的演化重要阶段。</p>
  </div>
</template>

<script>
export default {
  name: "Cretaceous_page3",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
