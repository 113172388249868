<template>
  <div class="Article">
    <span class="title"
      >侏罗纪简介
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      　侏罗纪( 距今约2.08亿年～距今1.44亿年,爬行动物和裸子植物的时代)
      属于中生代中期。中生代第二个纪。这一时期形成的地层称侏罗系,位于三叠系之上、白垩系之下。超级陆块盘古大陆此时真正开始分裂。大陆地壳上的缝生成了大西洋。非洲开始从南美洲裂开,而印度则准备移向亚洲。
    </p>
    <p class="p">
      侏罗纪生物发展史上出现了一些重要事件,引人注意。如恐龙成为陆地的统治者,翼龙类和鸟类出现,哺乳动物开始发展等等。陆生的裸子植物发展到极盛期。淡水无脊椎动物的双壳类、腹足类、叶肢介、介形虫及昆虫迅速发展。海生的菊石、双壳类、箭石仍为重要成员,六射珊瑚从三叠纪到侏罗纪的变化很小。棘皮动物的海胆自侏罗纪开始占领了重要地位。
    </p>
    <img src="./images/图片1.jpg" alt="" class="img" />
    <p class="p">
      侏罗纪时爬行动物迅速发展。槽齿类绝灭,海生的幻龙类也绝灭了。恐龙的进化类型——鸟臀类的四个主要类型中有两个繁盛于侏罗纪,飞行的爬行动物第一次滑翔于天空之中。鸟类首次出现,这是动物生命史上的重要变革之一。恐龙的另一类型——蜥臀类在侏罗纪有两类最为繁盛:一类是食肉的恐龙,另一类是笨重的植食恐龙。海生的爬行类中主要是鱼龙及蛇颈龙,它们成为海洋环境中不可忽视的成员。
    </p>
    <p class="p">
      三叠纪晚期出现的一部分最原始的哺乳动物在侏罗纪晚期已濒于绝灭。早侏罗世新产生了哺乳动物的另一些早期类型——多瘤齿兽类,它被认为是植食的类型,至新生代早期绝灭。而中侏罗世出现的古兽类一般被认为是有袋类和有胎盘哺乳动物的祖先。
    </p>
    <p class="p">
      软骨硬鳞鱼类在侏罗纪已开始衰退,被全骨鱼代替。发现于三叠纪的最早的真骨鱼类到了侏罗纪晚期才有了较大发展,数量增多,但种类较少。
    </p>
    <p class="p">
      侏罗纪的菊石更为进化,主要表现在缝合线的复杂化上,壳饰和壳形也日趋多样化,可能是菊石为适应不同海洋环境及多种生活方式所致。侏罗纪的海相双壳类很丰富,非海相双壳类也迅速发展起来,它们在陆相地层的划分与对比上起了重要作用。
    </p>
    <p class="p">
      侏罗纪是裸子植物的极盛期。苏铁类和银杏类的发展达到了高峰,松柏类也占到很重要的地位。
    </p>
  </div>
</template>

<script>
export default {
  name: "Jurassic_page1",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
