<template>
  <div class="Lunbotu">
    <el-carousel trigger="click" :height="height">
      <el-carousel-item v-for="item in imagesArr" :key="item">
        <img :src="'/' + item" alt="" style="width: 100%; display: block" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "Lunbotu",
  data() {
    return {
      imagesArr: ['轮播图4.jpg','轮播图5.jpg'],
      height: (window.innerWidth * 5) / 12 + "px",
    };
  },
  methods: {
    //重新调整大小
    reSize(width) {
      this.height = (width * 5) / 12 + "px";
    },
  },
  //挂载
  mounted() {
    window.onresize = () => {
      this.reSize(window.innerWidth);
    };
  },
};
</script>

<style scoped>
.Lunbotu {
  background-color: #ffffff;
  background-image: linear-gradient(
    109deg,
    #ffffff 0%,
    #e3effe 50%,
    #ddd6d6 100%
  );
}
</style>
