<template>
  <div class="CheckTicket">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      style="width: 600px"
      label-position="top"
    >
      <el-form-item label="手机号：" required prop="telPhone">
        <el-row type="flex"
          ><el-col :span="12"
            ><el-input v-model="ruleForm.telPhone"></el-input
          ></el-col>
          <el-col :offset="2"
            ><el-button type="primary" @click="check">查询</el-button></el-col
          ></el-row
        >
      </el-form-item>
    </el-form>
    <div class="Content" v-loading="loading">
      <el-empty v-if="isShow"></el-empty>
      <el-card :body-style="{ padding: '0px' }" v-for=" ticket in ticketArr" :key="ticket.ticektId">
        <img
          :src="'https://localhost:7270/QRCode/'+ticket.ticketUrl+'.png'"
          class="image"
        />
        <div style="padding: 14px">
          <div class="p"><label>姓名：</label><span>{{ticket.name}}</span></div>
          <div class="p"><label>票号：</label><span>{{ticket.ticketId}}</span></div>
          <div class="p"><label>身份证号码：</label>{{ticket.userID}}</div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { checkTicket } from "@/api/api";
export default {
  name: "CheckTicket",
  data() {
    return {
      loading: false,
      isShow: false,
      currentDate: new Date(),
      ticketArr:[
      ],
      ruleForm: {
        telPhone: "",
      },
      rules: {
        telPhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async check() {
      this.loading = true;
      let result = await checkTicket(this.ruleForm.telPhone);
      setTimeout(() => {
        if (result.status == 200 && result.data.ticket.length > 0) {
        console.log("有数据");
        this.loading = false;
        this.ticketArr = result.data.ticket
        console.log(this.ticketArr);
      } else {
        this.loading = false;
        this.isShow = true;
      }
      console.log(result);
      }, 2000);
      
    },
  },
};
</script>

<style scoped>
.CheckTicket {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Content {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
}

.el-card {
  width: 280px;
  margin-right: 1%;
  height: 400px;
}

.image {
  width: 100%;
  display: block;
}

.p{
  margin: 2px 0;
}
</style>
