<template>
  <div class="Article">
    <span class="title"
      >侏罗纪地层划分及重大事件
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      　海相侏罗纪地层富含化石,特别是菊石类特征明显,保存完全。据此,1815年,英国的W.史密斯提出利用古生物化石划分、对比地层的见解。1842年,法国的A.C.多比尼提出比统更小的年代地层单位阶,并命名了侏罗纪大部分阶名。1856年德国的A.奥佩尔则提出较详细的菊石带划分。侏罗纪地层正式划分为3统、11阶和74菊石带。
    </p>
    <p class="p">
      下侏罗统(里阿斯统)分为赫唐阶、辛涅缪尔阶、普林斯巴赫阶和托尔阶;中侏罗统(道格统)分为阿林阶、巴柔阶、巴通阶、卡洛阶;上侏罗统(麻姆统)分为牛津阶、基末里阶、提唐阶(伏尔加阶)、贝利阿斯阶。详细的菊石分带为全球范围海相侏罗系的划分、对比提供了良好的基础。在海相侏罗系顶界和统的划分方面,目前国际上仍未统一。中国的侏罗纪地层以陆相沉积为主。由于陆生生物演化速度和分布广度都不及菊石,所以陆相侏罗系的研究精度相对较低。
    </p>
    <p class="p">
      侏罗纪时发生过一些明显的地质、生物事件。最大海侵事件发生于晚侏罗世基末里期,与联合古陆分裂和新海洋扩张速率增强事件相吻合。环太平洋带的内华达运动也发生于基末里期,这可能显示联合古陆增强分裂与古太平洋板块加速俯冲事件之间存在着某种联系。
    </p>
    <p class="p">
      晚基末里期起,海生动物中出现特提斯大区和北方大区的明显分开,反映古气候分带和古地理隔离程度的加强。中侏罗世末的降温事件在欧亚大陆许多地方均有反映。近年来在波兰、西班牙中、上侏罗统界线层中发现了地内罕见的铱、锇异常,有人认为是地外小星体撞击地球的结果。
    </p>
  </div>
</template>

<script>
export default {
  name: "Jurassic_page4",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
