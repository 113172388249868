<template>
  <div class="QuitTicket">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-position="top"
      class="demo-ruleForm"
      style="width: 600px"
    >
      <el-form-item label="票号：" required prop="ticketId">
        <el-row type="flex"
          ><el-col
            ><el-input v-model.number="ruleForm.ticketId"></el-input
          ></el-col>
          <el-col :offset="2"
            ><el-button type="primary" @click="quit">退票</el-button></el-col
          ></el-row
        >
      </el-form-item>
    </el-form>
    <div class="Content" v-loading="loading">
      <component :is="Result"></component>
    </div>
  </div>
</template>

<script>
import Success from "@/components/Ticket/Result/Success";
import Error from "@/components/Ticket/Result/Error";
import { returnTicket } from "@/api/api";
export default {
  name: "QuitTicket",
  components: {
    Success,
    Error,
  },
  data() {
    return {
      Result: "",
      loading: false,
      ruleForm: {
        ticketId: "",
      },
      rules: {
        ticketId: [
          {
            required: true,
            message: "请输入票号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async quit() {
      this.loading = true;
      let result = await returnTicket(this.ruleForm.ticketId);
      if (result.status == 200) {
        this.loading = false;
        this.Result = "Success";
      } else {
        this.loading = false;
        this.Result = "Error";
      }
    },
  },
};
</script>

<style scoped>
.QuitTicket {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
