<template>
  <div class="Report">
    <el-tabs type="border-card">
      <el-tab-pane label="申报管理"><CulturalRelic /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CulturalRelic from "@/components/Admin/items/Report/CulturalRelic";
export default {
  name: "Report",
  components: {
    CulturalRelic,
  },
};
</script>

<style scoped>
.el-tabs {
  height: 100vh;
  overflow: auto;
}
</style>
