<template>
  <div class="Article">
    <span class="title"
      >三叠纪的复苏
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      三叠纪在生命演化史中的重要性就在于这次毁灭级的PTME。这次大灭彻底摧毁了当时的生态系统。一般的大规模灭绝，各种生态系统中多少会存留一些关键物种，它们会迅速填满大灭绝期间空出的生态位。因此，大灭绝前的食物网大框架，一定程度上来说，是没有变更的。但PTME并非如此。
    </p>
    <p class="p">
      关于大灭绝后的生态系统重建，并没有成熟的理论。通过观察局部生态系统的演替，可以推测那些快速繁殖的类群将会抓住这一机会，迅速辐射开来。接着它们又会被一些关键的动植物取而代之。这些动植物是一个长期生态系统形成的基础。因为稳健的生态系统通常是自下而上地重建的——先是小型植物，然后是大型植物、食草动物、还有食肉动物。
    </p>
    <p class="p">
      PTME的毁灭性极大，加上遭到破坏的物理环境也没有复原，因而整个恢复过程十分缓慢。而且，三叠纪最初的600万年间，还发生了3次全球变暖。这些事件冲击程度并不亚于PTME，是否有地质驱动因素的存在仍有争论。总而言之，那些快速演化的物种，如有孔虫（foraminifera）和鹦鹉螺（ammonites），在某种程度上已经开始恢复了，但又接二连三的遭到破坏，持续了600万年，直到海洋大气系统恢复正常。
    </p>
    
  </div>
</template>

<script>
export default {
    name:'Triassic_page3'
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img{
    height: 400px;
}
</style>