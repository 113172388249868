<template>
  <div>
    <component :is="this.$route.query.articalName"></component>
  </div>
</template>

<script>
import Triassic_page1 from "@/components/CenturyArchives/Articals/Triassic/Triassic_page1";
import Triassic_page2 from "@/components/CenturyArchives/Articals/Triassic/Triassic_page2";
import Triassic_page3 from "@/components/CenturyArchives/Articals/Triassic/Triassic_page3";
import Triassic_page4 from "@/components/CenturyArchives/Articals/Triassic/Triassic_page4";
import Triassic_page5 from "@/components/CenturyArchives/Articals/Triassic/Triassic_page5";
import Triassic_page6 from "@/components/CenturyArchives/Articals/Triassic/Triassic_page6";
import Jurassic_page1 from '@/components/CenturyArchives/Articals/Jurassic/Jurassic_page1'
import Jurassic_page2 from '@/components/CenturyArchives/Articals/Jurassic/Jurassic_page2'
import Jurassic_page3 from '@/components/CenturyArchives/Articals/Jurassic/Jurassic_page3'
import Jurassic_page4 from '@/components/CenturyArchives/Articals/Jurassic/Jurassic_page4'
import Cretaceous_page1 from '@/components/CenturyArchives/Articals/Cretaceous/Cretaceous_page1'
import Cretaceous_page2 from '@/components/CenturyArchives/Articals/Cretaceous/Cretaceous_page2'
import Cretaceous_page3 from '@/components/CenturyArchives/Articals/Cretaceous/Cretaceous_page3'
import Cretaceous_page4 from '@/components/CenturyArchives/Articals/Cretaceous/Cretaceous_page4'
import Cretaceous_page5 from '@/components/CenturyArchives/Articals/Cretaceous/Cretaceous_page5'
import Cretaceous_page6 from '@/components/CenturyArchives/Articals/Cretaceous/Cretaceous_page6'
import Cretaceous_page7 from '@/components/CenturyArchives/Articals/Cretaceous/Cretaceous_page7'

export default {
  name: "Articles",
  components: {
    Triassic_page1,
    Triassic_page2,
    Triassic_page3,
    Triassic_page4,
    Triassic_page5,
    Triassic_page6,
    Jurassic_page1,
    Jurassic_page2,
    Jurassic_page3,
    Jurassic_page4,
    Cretaceous_page1,
    Cretaceous_page2,
    Cretaceous_page3,
    Cretaceous_page4,
    Cretaceous_page5,
    Cretaceous_page6,
    Cretaceous_page7
  },
  async mounted() {
    console.log(this.$route.query.articalName);
  },
  methods: {
    test() {
      console.log(this.$route.query.articalName);
    },
  },
};
</script>

<style scoped>
</style>