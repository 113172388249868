<template>
  <div class="Article">
    <span class="title"
      >白垩纪的气候环境
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      白垩纪的海平面变化大、气候温暖，显示有大面积的陆地由温暖的浅海覆盖。白垩纪是以欧洲常见的白垩层为名，但在全球其他地区，白垩纪的地层主要由海相的石灰岩层构成，这些海相石灰岩层是在温暖的浅海环境形成。高的海平面会造成大范围的沉降作用，因此形成厚的沉积层。由于白垩纪的地层厚、时代较近，全球各地常发现白垩纪地层的露头。
    </p>
    <p class="p">
      白垩纪地层中常见白垩的成分。白垩是由海生颗石藻的钙质外壳微粒（球石粒）沉积而成；颗石藻是种白垩纪常见的藻类。
    </p>
    <p class="p">
      在欧洲西北部，常发现上白垩纪的白垩沉积层，包含：英格兰南岸的多佛白色峭壁、法国诺曼底海岸、以及低地国家、德国北部、丹麦沿岸。白垩的质地并不坚固，因此这些沉积层的质地松散。这些地层还包含石灰岩、砂质岩。这些地层可发现海胆、箭石、菊石、以及海生爬行动物（例如沧龙）的化石。
    <p class="p">
     欧洲南部的白垩纪地层多为海相地层，主要由石灰岩、与少数的泥灰构成。在白垩纪时期，阿尔卑斯山造山运动还没发生，所以欧洲南部的白垩纪地层当时多为特提斯洋周围的大陆棚。
    </p>
    <p class="p">
      在白垩纪中期，海洋低层的流动滞缓，造成海洋的缺氧环境。全球各地的许多黑色页岩层，即是在这段时期的缺氧环境形成。这些页岩层是重要的石油、天然气来源，北海便是如此。
    </p>
    <p class="p">
     巴列姆阶时期的气候出现寒冷的趋势，这个变化自侏罗纪最后一期就已开始。高纬度地区的降雪增加，而热带地区比三叠纪、侏罗纪更为潮湿。但是，冰河仅出现高纬度地区的高山，而较低纬度仍可见季节性的降雪。
    </p>
    <p class="p">在巴列姆阶末期，气温开始上升，持续到白垩纪末期。气温上升的原因是密集的火山爆发，制造大量的二氧化碳进入大气层中。中洋脊沿线形成许多热柱，造成海平面的上升，大陆地壳的许多地区由浅海覆盖着。位在赤道地区的特提斯洋，有助于全球暖化。在阿拉斯加州与格陵兰发现的植物化石，以及自白垩纪南纬15度地区发现的恐龙化石，证明白垩纪的气温相当温暖。</p>
    <p class="p">热带地区与极区间的温度梯度平缓，原因可能是海洋的流动停滞，并造成行星风系的虚弱。分布广泛的油页岩层，以及缺氧事件，可证实海洋的流动停滞。根据沉积层的研究指出，热带的海水表面温度约为42℃，高于现今约17℃；而全球的海水平均表面温度为37℃。而海洋底层温度高于我们现时的温度约15到20℃。</p>
    
  </div>
</template>

<script>
export default {
  name: "Cretaceous_page4",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
