<template>
  <div class="Article">
    <span class="title"
      >三叠纪环境
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      在三叠纪，所有大陆融合为超级大陆（supercontinent）——泛大陆（Pangaea）。大融合始于大约300Myr以前的石炭纪，在地球板块的不断变化中，冈瓦纳（现代南美洲，非洲，印度，南极洲和澳大利亚）向北移动，与劳亚（现代的北美、欧洲和亚洲）以及东边的包括西伯利亚和中国部分地区在内的更小的板块会合。
    </p>
    <img src="./images/图片2.png" alt="" class="img">
    <p class="p">
      左图：三叠纪的时间跨度约为50Myr，在开始和结束时都有大规模灭绝。右图。三叠纪时期的世界地理图，显示了主要的气候带。特别是早三叠世的干旱气候 （米色）的范围。大多数生物都在温带地区（绿色）。绘制者：Simon Powell, 版权：University of Bristol.
    </p>
    <p class="p">
      三叠纪气候温暖而稳定，没有极地冰盖。北美、南美、欧洲和非洲的大部分地区都有广阔的干旱地带。暖温带向两极延伸，北部达亚洲的俄罗斯，南部至印度、澳大利亚和南极洲（图1B）。海洋生物以来自泛大洋（Panthalassa）和特提斯海（Tethys）的化石为代表，而最著名的陆生生物来自北温带和南温带。
    </p>
    <img src="./images/图片3.png" alt="" class="img">
    <p class="p">
      泛大陆在三叠纪末期开始分裂，当时大西洋地区，被称为中大西洋岩浆省（Central Atlantic Magmatic Province，CAMP），发生了大规模火山喷发，向现在的北美东海岸、欧洲西南部部分地区和非洲东北部喷出大量玄武岩。这种岩浆活动与北大西洋的裂谷作用有关，当时北美向西旋转，欧洲向东旋转。后来，在大约100Myr的白垩纪，南大西洋裂开了，南美洲和非洲分开了，印度、澳大利亚和南极洲开始向它们现在所处的位置庄严地前进。
    </p>

    
  </div>
</template>

<script>
export default {
    name:'Triassic_page2'
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img{
    height: 400px;
}
</style>