<template>
  <div class="Article">
    <span class="title"
      >岩石年代测定和化石记录
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      在教科书中，生命的历史往往是以一个年代相当模糊的岩石记录为背景来描绘的。然而，在过去的20年里，岩石年代测定的质量有了巨大的进步，因此地质年代的尺度比几年前人们所认为的要丰富得多，也更精确。这要归功于放射性同位素定年法，其他一些地质年代学的新方法，以及在确定关键时间界限时国际协作的贡献。
    </p>
    <p class="p">
      1834年，当弗里德里克·冯·艾伯蒂（Friedrich von Alberti）命名三叠纪（图1A）时，地质学家对地球的年龄一无所知，只知道它非常古老。他用“三叠纪”一词来描述在德国差异明显的三个岩石分区，即Buntsandstein（本特砂岩）、Muschelkalk（壳灰岩）和Keuper（考依波泥灰岩）。1896年法国物理学家贝可勒尔（Henri Becquerel）发现了天然放射性现象。1904年，欧内斯特·卢瑟福（Ernest Rutherford）提出，放射性衰变可能可以像节拍器一样，用于估算远久的岩石时间，第一个地质年代表（1913）就是由阿瑟·霍姆斯（Arthur Holmes）根据放射性元素测定结果建立的。尽管当时的技术并不完善，但这些早期数据实际上和现代的估算值相差并不大，只是整体误差不小。直到1990年，放射性同位素数据的误差都一直高居在±5%的水平，这意味着200Myr可能是190Mayr或者是210Myr。这种不确定性远不能令演化速率研究者满意。
    </p>
    <p class="p">
      然而，在过去的20年里，这些数据的误差已经逐渐缩小到1%，故而现在的地质学家们争论的是二叠纪-三叠纪大灭绝（详细情况见下文）是持续了6万年还是18万年。精度的提高源于对岩石样本取样越来越谨慎，质谱仪技术的不断改进，以及使用不同同位素序列对同一岩石进行密集交叉匹配。
    </p>
    <p class="p">对化石记录质量的争论和质疑从未间断过。在过去的20年里，中国、俄罗斯和南美洲出现了大量三叠纪化石的新记录。证实了一些已有的模式，还增加了一些新的信息。比如在某些动物化石和植物化石中，几个厘米或许代表了数万年或数十万年的时间跨度。</p>
  </div>
</template>

<script>
export default {
    name:'Triassic_page5'
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img{
    height: 400px;
}
</style>