<template>
  <div class="Declare">
    <Header />
    <div class="banner">
      <img src="../images/background/Banner1.jpg" alt="" />
    </div>
    <div class="webAdress">
      <div><img src="../images/icons/401位置.png" alt="" /> 位置：文物申报</div>
    </div>
    <div class="Main-Container">
      <el-form ref="form" :model="form" label-width="80px" style="width: 800px">
        <el-form-item label="文物标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="文物类别">
          <el-select v-model="form.type" placeholder="类别">
            <el-option label="化石" value="化石"></el-option>
            <el-option label="文物" value="文物"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文物地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="联系姓名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            type="text"
            placeholder="请输入手机号"
            v-model="form.phone"
            maxlength="11"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="发现时间">
          <el-col :span="11">
            <el-form-item prop="time">
              <el-date-picker
                v-model="form.time"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="照片资料">
          <el-upload
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :on-change="fileInto"
            ref="photo"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="详细内容">
          <el-input
            type="textarea"
            v-model="form.detail"
            class="content"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即上报</el-button>
          <el-button @click="log">清空</el-button>
        </el-form-item>
      </el-form>
    </div>
    <Footer />
  </div>
</template>

<script>
import { CulturalRelic } from "@/api/api";
import { Message } from "element-ui";
import Header from "@/components/common/Header";
import Footer from "@/components/common/Footer";
export default {
  name: "Declare",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      form: {
        fileArr: [],
        title: "",
        type: "",
        address: "",
        name: "",
        phone: "",
        time: "",
        delivery: false,
        detail: "",
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    fileInto(file, fileList) {
      this.form.fileArr = fileList;
    },
    async onSubmit() {
      const formDate = new FormData();
      for (let i = 0; i < this.form.fileArr.length; i++) {
        formDate.append("files", this.form.fileArr[i].raw);
      }
      console.log(this.form.fileArr[0].raw);

      formDate.append("title", this.form.title);
      formDate.append("kinds", this.form.type);
      formDate.append("address", this.form.address);
      formDate.append("name", this.form.name);
      formDate.append("telPhone", this.form.phone);
      formDate.append("Time", "2019-7-7");
      formDate.append("detail", this.form.detail);
      let result = await CulturalRelic(formDate);
      if (result.status == 200) {
        this.form = {
          fileArr: [],
          title: "",
          type: "",
          address: "",
          name: "",
          phone: "",
          time: "",
          delivery: false,
          detail: "",
        };
        this.$refs.photo.clearFiles()
        Message.success({
          message: "申报成功！",
        });
      } else {
        Message.success({
          message: "申报失败！",
        });
      }
      console.log(result);
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    log() {
      console.log(document.querySelector(".content").innerHTML);
    },
  },
};
</script>

<style scoped>
/* 改变导航样式 */
.nav {
  min-width: 1400px;
  background-color: rgba(0, 0, 0, 0.5);
}
/*  */
.banner {
  position: relative;
  min-width: 1400px;
}
.banner > img {
  width: 100%;
}
.webAdress {
  margin: 30px 0;
}

.webAdress div {
  height: 20px;
  width: 1400px;
  margin: 0 auto;
}
.webAdress div > img {
  height: 20px;
  vertical-align: bottom;
}

.Main-Container {
  width: 1400px;
  background: #fff;
  margin: 60px auto;
  display: flex;
  justify-content: center;
}
</style>
