<template>
  <div class="Article">
    <span class="title"
      >三叠纪概要
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
        每一段地质年代，都发生过重大事件。但三叠纪，特别多。
    </p>
    <p class="p">
        在这段时间里，盘古超大陆最终形成，还有生物集群灭绝及演替、全球性海退、野火事件等等。环境发生着沧海桑田的巨变，在这种多样化和复杂化的环境变迁中，一批批能游泳的，会飞行的动物陆续出现。本文翻译了Benton MJ于2016年发表在Current Biology上的一篇文章[1]，同时拟在昆虫方面整理一些有趣的进展。
    </p>
    <img src="./images/图片1.png" alt="" class="img">
    <p class="p">
        2.52亿年到2.01亿年间的三叠纪，是现代生态系统起源的关键。地球历史可以被划分为11个地质系统或时期，其中的第7个是显生宙，所有主要的现代脊椎动物类群都是在这段时间内起源的——鲨鱼（neoselachian sharks）、新鳍鱼类的硬骨鱼（neopterygian bony fishes）、滑体两栖类（lissamphibians）、海龟（turtles）、鳞龙（lepidosaurs）、鳄（crocodilomorphs）、和哺乳动物（mammals）。如果鸟类算是兽脚亚目恐龙（theropod dinosaurs），那么这个分支也起源于三叠纪。
    </p>
    <p class="p">
        在海洋中发生了一场重要的生态系统重塑和快速进化事件——中生代海洋革命（the Mesozoic Marine Revolution）。自此，一群全新的贪婪的食肉动物与它们的猎物间展开了一场宏大的军备竞赛。
    </p>
    <p class="p">
        在这里，我将探索一个看似遥远的地质年代，它记录了生命历史中一段独特的历程。同时，一些关键的方法问题也顺带提了一下。比如岩石年代测定和化石记录方法的改进，在宏观进化研究中使用大型系统发育树，以及新方法如何使古生物学家不仅能识别模式，而且能识别远古的生命历史过程。
    </p>
    
  </div>
</template>

<script>
export default {
    name:'Triassic_page1'
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img{
    height: 400px;
}
</style>