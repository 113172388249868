<template>
  <div class="TriassicArchives">
    <div class="bg">
      <div class="center">
        <div class="right" style="top: 155px">
          <p>208.5百万年前</p>
          <p>瑞替期</p>
        </div>
        <div class="left" style="top: 245px">
          <p>227百万年前</p>
          <p>诺利期</p>
        </div>
        <div class="right" style="top: 268px">
          <p>237百万年前</p>
          <p>卡尼期</p>
        </div>
        <div class="right" style="top: 381px">
          <p>242百万年前</p>
          <p>拉丁期</p>
        </div>
        <div class="left" style="top: 450px">
          <p>247.2百万年前</p>
          <p>安尼期</p>
        </div>
        <div class="right" style="top: 494px">
          <p>251.2百万年前</p>
          <p>奥伦尼克朗</p>
        </div>
        <div class="left" style="top: 563px">
          <p>251.902±0.024百万年前</p>
          <p>印度期</p>
        </div>
        <div class="right" style="top: 607px">
          <p>45百万年前</p>
          <p>提通期</p>
        </div>
        <div class="left" style="top: 676px">
          <p>52.5百万年前</p>
          <p>启莫里期</p>
        </div>
        <div class="left" style="top: 789px">
          <p>57.5百万年前</p>
          <p>牛津期</p>
        </div>
        <div class="right" style="top: 810px">
          <p>62.5百万年前</p>
          <p>卡洛夫期</p>
        </div>
        <div class="left" style="top: 902px">
          <p>66百万年前</p>
          <p>巴通期</p>
        </div>
        <div class="left" style="top: 1015px">
          <p>68百万年前</p>
          <p>巴柔期</p>
        </div>
        <div class="right" style="top: 1015px">
          <p>70百万年前</p>
          <p>阿林期</p>
        </div>
        <div class="left" style="top: 1128px">
          <p>73.5百万年前</p>
          <p>托阿尔期</p>
        </div>
        <div class="right" style="top: 1128px">
          <p>82.5百万年前</p>
          <p>普林斯巴期</p>
        </div>
        <div class="left" style="top: 1241px">
          <p>91百万年前</p>
          <p>辛涅缪尔期</p>
        </div>
        <div class="right" style="top: 1241px">
          <p>30百万年前</p>
          <p>赫塘期</p>
        </div>
        <div class="left" style="top: 1354px">
          <p>65百万年前</p>
          <p>马斯特里赫特期</p>
        </div>
        <div class="right" style="top: 1354px">
          <p>72百万年前</p>
          <p>坎帕期</p>
        </div>
        <div class="left" style="top: 1467px">
          <p>83百万年前</p>
          <p>桑托期</p>
        </div>
        <div class="right" style="top: 1467px">
          <p>86百万年前</p>
          <p>科尼亚克期</p>
        </div>
        <div class="left" style="top: 1580px">
          <p>90百万年前</p>
          <p>土仑期</p>
        </div>
        <div class="right" style="top: 1580px">
          <p>93百万年前</p>
          <p>森诺曼期</p>
        </div>
        <div class="right" style="top: 1693px">
          <p>00百万年前</p>
          <p>阿尔布期</p>
        </div>
        <div class="left" style="top: 1770px">
          <p>12.5百万年前</p>
          <p>阿普第期</p>
        </div>
        <div class="right" style="top: 1800px">
          <p>25百万年前</p>
          <p>巴列姆期</p>
        </div>
        <div class="right" style="top: 1913px">
          <p>29百万年前</p>
          <p>豪特里维期</p>
        </div>
         <div class="left" style="top: 1970px">
          <p>33百万年前</p>
          <p>凡蓝今期</p>
        </div>
                <div class="right" style="top: 2026px">
          <p>40百万年前</p>
          <p>贝里亚期</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bg {
  width: 1024px;
  height: 2730px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../images/knowledgeBg.jpg);
}
.center {
  width: 1px;
  height: 100%;
  position: relative;
  left: 50%;
}
.left {
  position: absolute;
  cursor: pointer;
  right: 90px;
  text-align: right;
  height: 60px;
  width: 200px;
}
p {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
}
.right {
  position: absolute;
  cursor: pointer;
  left: 90px;
  height: 60px;
  width: 200px;

}
</style>
