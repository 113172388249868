<template>
  <div class="Footer">
    <div class="Top">
      <img src="../../images/logos/logo.png" alt="" />
    </div>
    <div class="Middle">
      <ul class="QRcode">
        <li class="QRcode_outer">
            <img src="../../images/icons/博物馆.png" alt="">
            <p>自然博物馆</p>
        </li>
        <li class="QRcode_outer">
            <img src="../../images/icons/微博.png" alt="">
            <p>新浪微博</p>
        </li>
        <li class="QRcode_outer">
            <img src="../../images/icons/公众号.png" alt="">
            <p>微信公众号</p>
        </li>
        <li class="QRcode_outer">
            <img src="../../images/icons/抖音.png" alt="">
            <p>官方抖音</p>
        </li>
      </ul>
    </div>
    <div class="Bottom">
      <div class="copyright">
        <p>Copyright © 2023 侏罗纪世纪(Jurassic Century)</p>
        <div class="outer">
          <span>渝ICP备07031873号-5 </span>
          <img src="../../images/icons/beian.png" alt="" class="beian" />
          <span>渝公网安备1101155332006642号</span>
        </div>
        <div class="outer">
          <span>网站访问量：</span>
          <div class="webCount">
            <span>1</span>
            <span>8</span>
            <span>7</span>
            <span>8</span>
            <span>5</span>
            <span>3</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.Footer {
  background: #1b1b1b;
  min-width: 1400px;
}

.Footer > .Top {
  height: 80px;
}
.Footer > .Top > img {
  position: relative;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
}

.Footer > .Middle {
  padding: 10px 30px;
}
.Middle .QRcode {
  padding: 10px 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  display: flex;
  justify-content: space-between;
}

.QRcode > .QRcode_outer {
  width: 80px;
  height: 80px;
  background: #546457;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: .5s;
}

.QRcode_outer:hover{
  background-color: #1b1b1b;
  border: 1px dashed white;
}

.QRcode_outer > img{
    width: 40px;
    height: 40px;
}

.QRcode_outer > p{
    position: absolute;
    width: 120%;
    text-align: center;
    left: -10%;
    top: 110%;
    color: #fff;
}

.Footer > .Bottom {
  color: #fff;
  text-align: center;
  font-size: 14px;
}

.Footer > .Bottom > .copyright {
  width: 1400px;
  position: relative;
  left: 50%;
  padding: 20px 30px;
  transform: translateX(-50%);
}
.copyright > p {
  padding: 5px 0;
}
.copyright .webCount {
  display: inline-block;
}
.webCount > span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  background-color: #989f99;
  color: #394138;
  margin: 0 3px;
}
.copyright > .outer {
  padding: 5px 0;
}
.beian {
  vertical-align: bottom;
  height: 100%;
}
</style>
