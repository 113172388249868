import { render, staticRenderFns } from "./Triassic_page6.vue?vue&type=template&id=7ef29723&scoped=true&"
import script from "./Triassic_page6.vue?vue&type=script&lang=js&"
export * from "./Triassic_page6.vue?vue&type=script&lang=js&"
import style0 from "./Triassic_page6.vue?vue&type=style&index=0&id=7ef29723&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef29723",
  null
  
)

export default component.exports