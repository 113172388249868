<template>
  <div class="CulturalRelic">
    <el-table
      :data="filter"
      :default-sort="{ prop: 'id', order: 'ascending' }"
      style="width: 100%"
    >
      <el-table-column label="申报编号" prop="id" width="100px" sortable>
      </el-table-column>
      <el-table-column label="申报标题" prop="title" width="250px">
      </el-table-column>

      <el-table-column label="申报时间" prop="time" width="180px">
        <template slot-scope="scope">
          <el-tag>{{ scope.row.time.slice(0, 10) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="申报类别" prop="kinds" width="100px">
      </el-table-column>
      <el-table-column label="申报人姓名" prop="name" width="180px">
      </el-table-column>
      <el-table-column label="申报人电话" prop="telPhone"> </el-table-column>
      <el-table-column align="right" min-width="300px">
        <template slot="header" slot-scope="scope">
          <el-col :span="12" :offset="12">
            <el-input
              v-model="search"
              size="mini"
              placeholder="输入申报人姓名搜索"
          /></el-col>
        </template>
        <template slot-scope="scope">
          <el-button size="mini" @click="checkAll(scope.$index, scope.row)"
            >查看详情</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            v-if="false"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="detailData.title"
      :visible.sync="dialogVisible"
      width="50%"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div class="p">发现时间：{{ detailData.time.slice(0, 10) }}</div>
      <div class="p">发现类别：{{ detailData.kinds }}</div>
      <div class="p">发现地址：{{ detailData.address }}</div>
      <div class="p">发现人姓名：{{ detailData.name }}</div>
      <div class="p">发现人电话：{{ detailData.telPhone }}</div>
      <div class="images">
        <div class="p">相关图片</div>
        <div class="p" v-for="(img, index) in tempPicture" :key="index">
          <img :src="'https://localhost:7270/CRPicture/' + img" alt="" />
        </div>
        <div class="image">
          <img src="" alt="" />
        </div>
      </div>
      <div class="content">
        <div class="p">描述内容：</div>
        <div style="text-indent: 2em">{{ detailData.body }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAllCR, getCR } from "@/api/api";

export default {
  name: "CulturalRelic",
  data() {
    return {
      dialogVisible: false,
      tableData: [
        {
          id: 0,
          title: "标题",
          time: "时间",
          kinds: "类别",
          name: "发现人名字",
          telPhone: "电话",
        },
      ],
      detailData: {
        title: "标题",
        time: "时间",
        kinds: "类别",
        name: "发现人名字",
        telPhone: "电话",
        picture: "图片地址,awdafw,awfawf",
        address: "发现地址",
        body: "内容",
      },
      tempPicture: ["w.jpg", "s.jpg"],
      search: "",
    };
  },
  computed: {
    filter() {
      return this.tableData.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    async checkAll(index, row) {
      const token = localStorage.getItem("Token");
      let result = await getCR(token, row.id);
      if (result.status == 200) {
        console.log(result);
        this.detailData = result.data.culturalRelic;
        this.tempPicture = result.data.culturalRelic.picture
          .split(",")
          .filter((item) => {
            return item != "";
          });
        console.log(this.tempPicture);
        this.dialogVisible = true;
      }
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
  async mounted() {
    const token = localStorage.getItem("Token");
    let result = await getAllCR(token);
    console.log(result.data);
    if (result.status == 200) {
      this.tableData = result.data.culturalRelicS;
    }
  },
};
</script>

<style scoped>
.p {
  font-size: 14px;
  margin: 5px 0;
}
.p img {
  width: 100%;
}
</style>
