import Vue from "vue";
import VueRouter from "vue-router";
//备份原型对象已有的push|replace方法
let push = VueRouter.prototype.push;
let replace = VueRouter.prototype.replace;
//重写VueRouter.prototype原型对象的push|replace方法
VueRouter.prototype.push = function (location) {
  //调用push方法且篡改push方法内部this指向->vueRouter类的实例
  push.call(
    this,
    location,
    () => {},
    () => {}
  );
};
VueRouter.prototype.replace = function (location) {
  //调用push方法且篡改push方法内部this指向->vueRouter类的实例
  replace.apply(this, [location, () => {}, () => {}]);
};

Vue.use(VueRouter);

import Home from "@/view/Home";
//引入三叠纪
import Triassic from "@/view/Triassic";
import Jurassic from "@/view/Jurassic";
import Cretaceous from "@/view/Cretaceous";
//引入云博概况d
import Yunbo from "@/view/Yunbo";
import SoftwareIntroduce from "@/components/Yunbo/SoftwareIntroduce";
import DeveloperTeam from "@/components/Yunbo/DeveloperTeam";
import DeveloperCradle from "@/components/Yunbo/DeveloperCradle";
import Statement from "@/components/Yunbo/Statement";

//引入云馆藏
import Cloudcollections from "@/view/Cloudcollections";
import Theropods from "@/components/Cloudcollections/Theropods";
import Sauropods from "@/components/Cloudcollections/Sauropods";
import Ankylosaurs from "@/components/Cloudcollections/Ankylosaurs";
import Ornithischids from "@/components/Cloudcollections/Ornithischids";
import Ceratops from "@/components/Cloudcollections/Ceratops";
import Stegosauria from "@/components/Cloudcollections/Stegosauria";
import Pachycephalosauria from "@/components/Cloudcollections/Pachycephalosauria";
//引入文物申报
import Declare from "@/view/Declare";
//引入门票预约
import Ticket from "@/view/Ticket";
import AdultTicket from "@/components/Ticket/AdultTicket"; //成人票
import ChildTicket from "@/components/Ticket/ChildTicket"; //儿童票
import CheckTicket from "@/components/Ticket/CheckTicket"; //儿童票
import QuitTicket from "@/components/Ticket/QuitTicket"; //退票

//引入世纪档案
import CenturyArchives from "@/view/CenturyArchives";
import Big from "@/components/CenturyArchives/Big";
import Articles from "@/components/CenturyArchives/Articles";
import TriassicArchives from "@/components/CenturyArchives/TriassicArchives";
import JurassicArchives from "@/components/CenturyArchives/JurassicArchives";
import CretaceousArchives from "@/components/CenturyArchives/CretaceousArchives";

//引入后台
import Admin from "@/view/Admin";
import Login from "@/components/Admin/Login";
import AdminHome from "@/components/Admin/AdminHome";
import Models from "@/components/Admin/items/Models";

import Article from "@/components/Admin/items/Article";
import Tickets from "@/components/Admin/items/Tickets";
import Report from "@/components/Admin/items/Report";
const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    name: "Home",
    path: "/",
    component: Home,
  },
  //三叠纪
  {
    name: "Triassic",
    path: "/dinosaur3D/Triassic",
    component: Triassic,
  },
  {
    name: "Jurassic",
    path: "/dinosaur3D/Jurassic",
    component: Jurassic,
  },
  {
    name:'Cretaceous',
    path:'/dinosaur3D/Cretaceous',
    component:Cretaceous,
  },
  //云博概况
  {
    name: "Yunbo",
    path: "/Yunbo",
    component: Yunbo,
    redirect: "/Yunbo/SoftwareIntroduce",
    children: [
      {
        name: "SoftwareIntroduce",
        path: "/Yunbo/SoftwareIntroduce",
        meta: {
          nickname: "软件介绍",
        },
        component: SoftwareIntroduce,
      },
      {
        name: "DeveloperTeam",
        path: "/Yunbo/DeveloperTeam",
        meta: {
          nickname: "开发者团队",
        },
        component: DeveloperTeam,
      },
      {
        name: "DeveloperCradle",
        path: "/Yunbo/DeveloperCradle",
        meta: {
          nickname: "开发者摇篮",
        },
        component: DeveloperCradle,
      },
      {
        name: "Statement",
        path: "/Yunbo/Statement",
        meta: {
          nickname: "责任声明",
        },
        component: Statement,
      },
    ],
  },
  //云馆藏
  {
    name: "Cloudcollections",
    path: "/Cloudcollections",
    component: Cloudcollections,
    redirect: "/Cloudcollections/Sauropods",
    children: [
      {
        name: "Theropods",
        path: "/Cloudcollections/Theropods",
        component: Theropods,
        meta: {
          nickname: "兽脚类恐龙",
        },
      },
      {
        name: "Sauropods",
        path: "/Cloudcollections/Sauropods",
        component: Sauropods,
        meta: {
          nickname: "蜥脚类恐龙",
        },
      },
      {
        name: "Ankylosaurs",
        path: "/Cloudcollections/Ankylosaurs",
        component: Ankylosaurs,
        meta: {
          nickname: "甲龙类恐龙",
        },
      },
      {
        name: "Ornithischids",
        path: "/Cloudcollections/Ornithischids",
        component: Ornithischids,
        meta: {
          nickname: "鸟脚类恐龙",
        },
      },
      {
        name: "Ceratops",
        path: "/Cloudcollections/Ceratops",
        component: Ceratops,
        meta: {
          nickname: "角龙类恐龙",
        },
      },
      {
        name: "Stegosauria",
        path: "/Cloudcollections/Stegosauria",
        component: Stegosauria,
        meta: {
          nickname: "剑龙类恐龙",
        },
      },
      {
        name: "Pachycephalosauria",
        path: "/Cloudcollections/Pachycephalosauria",
        component: Pachycephalosauria,
        meta: {
          nickname: "肿头类恐龙",
        },
      },
    ],
  },
  //文物申报
  {
    name: "Declare",
    path: "/Declare",
    component: Declare,
  },
  //门票预约
  {
    name: "Ticket",
    path: "/Ticket",
    component: Ticket,
    redirect: "/Ticket/AdultTicket",
    children: [
      {
        name: "AdultTicket",
        path: "/Ticket/AdultTicket",
        component: AdultTicket,
        meta: {
          nickname: "成人票预约",
        },
      },
      {
        name: "ChildTicket",
        path: "/Ticket/ChildTicket",
        component: ChildTicket,
        meta: {
          nickname: "儿童票预约",
        },
      },
      {
        name: "CheckTicket",
        path: "/Ticket/CheckTicket",
        component: CheckTicket,
        meta: {
          nickname: "查询票信息",
        },
      },

      {
        name: "QuitTicket",
        path: "/Ticket/QuitTicket",
        component: QuitTicket,
        meta: {
          nickname: "申请退票",
        },
      },
    ],
  },
  //世纪档案
  {
    name: "CenturyArchives",
    path: "/CenturyArchives",
    component: CenturyArchives,
    redirect: "/CenturyArchives/Big",
    children: [
      {
        name: "Big",
        path: "/CenturyArchives/Big",
        meta: {
          nickname: "恐龙大事纪",
        },
        component: Big,
      },
      {
        name: "Articles",
        path: "/CenturyArchives/Articles",
        meta: {
          nickname: "事件文章",
        },
        component: Articles,
      },
      {
        name: "TriassicArchives",
        path: "/CenturyArchives/TriassicArchives",
        meta: {
          nickname: "三叠纪档案",
        },
        component: TriassicArchives,
      },
      {
        name: "JurassicArchives",
        path: "/CenturyArchives/JurassicArchives",
        meta: {
          nickname: "侏罗纪档案",
        },
        component: JurassicArchives,
      },
      {
        name: "CretaceousArchives",
        path: "/CenturyArchives/CretaceousArchives",
        meta: {
          nickname: "白垩纪档案",
        },
        component: CretaceousArchives,
      },
    ],
  },
  //后台
  {
    name: "Admin",
    path: "/Admin",
    component: Admin,
    redirect: "/Admin/Login",
    children: [
      {
        name: "Login",
        path: "/Admin/Login",
        component: Login,
      },
      {
        name: "AdminHome",
        path: "/Admin/AdminHome",
        component: AdminHome,
        redirect: "/Admin/AdminHome/Models",
        meta: {
          isAuth: true,
        },
        children: [
          {
            name: "Models",
            path: "/Admin/AdminHome/Models",
            component: Models,
            meta: {
              isAuth: true,
            },
          },
          {
            name: "Article",
            path: "/Admin/AdminHome/Article",
            component: Article,
            meta: {
              isAuth: true,
            },
          },
          {
            name: "Tickets",
            path: "/Admin/AdminHome/Tickets",
            component: Tickets,
            meta: {
              isAuth: true,
            },
          },
          {
            name: "Report",
            path: "/Admin/AdminHome/Report",
            component: Report,
            meta: {
              isAuth: true,
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.isAuth) {
    const token = localStorage.getItem("Token");
    if (token != null) {
      next();
    } else {
      router.push({ name: "Login" });
    }
  } else {
    next();
  }
});

//暴露router
export default router;
