<template>
  <div class="Article">
    <span class="title"
      >白垩纪的地质结构
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      白垩统下部是著名的韦尔登群陆相地层，包括哈斯丁层和韦尔登泥岩两部分；下白垩统上部是绿色砂岩层，包括阿普特和阿尔布两个期的沉积；上白垩统即狭义的白垩层，包括从赛诺曼期到坎潘早期地层，顶部缺失坎潘中晚期和马斯特里赫特期沉积。北美已经放弃了过去有些作者把下白垩统单独划分出来叫做“科马奇系”的做法，而采取了国际通用标准。但在广大的落基山区，白垩纪海侵是从早白垩世晚期开始的，普遍缺失早、中期沉积。日本学者认为，日本诸岛远离西欧,属不同生物地理区系，与建立在欧洲基础上的国际标准分阶难以直接对比，独立划分海相白垩系为K1～K66个单元，在不同相区，使用不同的地层名称。
    </p>
    <p class="p">
      陆相白垩系在东亚腹地非常发育，富含石油、煤、盐类等矿产以及各种淡水和陆生动植物化石。中华人民共和国仅西藏、新疆喀什地区、黑龙江省东部和台湾岛才有海相白垩系沉积，其余广大地区则不同程度地发育了陆相地层。东部沿海属环太平洋活动带，以红色及杂色岩层夹火山碎屑岩和熔岩为主；西北内陆盆地以杂色沉积岩层为主；西南和华中主要是红色岩层。东北下白垩统下部是含煤岩层，上部是湖相沉积，夹少许油页岩；上白垩统则是著名的含油岩系，与北美情况不同，含煤岩层很少。
    </p>
    <p class="p">
      中华人民共和国白垩系生物地层学的研究，自20世纪50年代以来，取得了很大进展，但由于以陆相地层为主，研究基础比较薄弱，至今尚未完成区域性的建组和建阶工作，在各个地区已经建立起来的分层还不能完善地与海相标准分层进行对比。侏罗－白垩系界线的划分成为长期争论的问题，看来在短期内还难以得到妥善解决。
    </p>
  </div>
</template>

<script>
export default {
  name: "Cretaceous_page5",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
