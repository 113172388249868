<template>
  <div class="Article">
    <span class="title"
      >大灭绝事件
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      剧烈的地壳运动和海陆变迁，导致了白垩纪生物界的巨大变化，中生代许多盛行和占优势的门类（如裸子植物、爬行动物、菊石和箭石等）后期相继衰落和绝灭，新兴的被子植物、鸟类、哺乳动物及腹足类、双壳类等都有所发展，预示着新的生物演化阶段——新生代的来临。
    </p>
    <p class="p">
      爬行类从晚侏罗世至早白垩世达到极盛，继续占领着海、陆、空。鸟类继续进化，其特征不断接近现代鸟类。哺乳类略有发展，出现了有袋类和原始有胎盘的真兽类。鱼类已完全的以真骨鱼类为主。
    </p>
    <p class="p">
      白垩纪的海生无脊椎动物最重要的门类仍为菊石纲，菊石在壳体大小、壳形、壳饰和缝合线类型上远较侏罗纪多样。白垩纪时南方古大陆继续解体，北方古大陆不断上升，气候变冷，季节性变化明显。杂食性、食虫性、以及食腐动物在这次灭绝事件中存活，可能因为它们的食性较多变化。白垩纪末期似乎没有完全的草食性或肉食性哺乳动物。哺乳动物与鸟类借由以昆虫、蚯蚓、蜗牛等动物为食，而在K-T事件中存活，而这些动物则以死亡的植物与动物为食。科学家假设，这些生物以生物的有机碎屑为生，因此得以在这次植物群崩溃的灭绝事件存活。
    </p>
    <img src="./images/图片3.jpg" alt="" class="img" />
    <p class="p">
      在河流生物群落中，只有少数动物灭亡；因为河流生物群落多以自陆地冲刷下来的生物有机碎屑为生，较少直接以活的植物为生。海洋也有类似的状况，但较为复杂。生存在浮游带的动物，所受到的影响远比生存在海床的动物还大。生存在浮游带的动物几乎以活的浮游植物为生，而生存在海床的动物，则以生物的有机碎屑为食，或者可转换成以生物的有机碎屑为食。
    </p>
    <p class="p">
      在这次灭绝事件存活下来的生物中，最大型的陆地动物是鳄鱼与离龙目，是半水生动物，并可以生物碎屑为生。现代鳄鱼可以食腐为生，并可长达数月未进食；幼年鳄鱼的体型小，成长速度慢，在头几年多以无脊椎动物、死亡的生物为食。这些特性可能是鳄鱼能够存活过白垩纪末灭绝事件的关键。
    </p>
    <img src="./images/图片4.jpg" alt="" class="img" />
    <p class="p">
      白垩纪是地球上海陆分布和生物界急剧变化、大西洋迅速开裂和火山活动频繁的时代，后期地势低平发生了广泛的海侵。晚白垩世被子植物代替裸子植物在陆上占优势，是植物界的一大变革。动物界在白垩纪末才发生重大变化，恐龙、菊石和其他许多生物类群大量灭绝，预示着新生代即将开始。对引起这次大规模生物灭绝的原因，在国际科学界展开了热烈的争论。颇为流行的“地外事件”学说认为，地球上一些地方，白垩-第三系界线上的粘土岩中铱元素异常高含量，是宇宙中一颗巨大的流星碰撞地球产生的类似核冬天效应的结果，据此，在世界各地确定白垩-第三系界线时，铱异常就是一条重要原则。但是，对“地外事件”持怀疑和反对意见的人也不少，他们认为，白垩纪末生物大规模灭绝是逐渐发生的，是生态改变的结果，而不是一次简单的天外来客撞击地球所引起的灾难。
    </p>
    <p class="p">
      西部该年代的地层主要为白垩沉积而得名。白垩纪位于侏罗纪和古近纪之间，约1亿3700万年（误差值为400万年）前至6500万年前（误差值为30万年）。白垩纪-第三纪灭绝事件是地质年代中最严重的大规模灭绝事件之一，包含恐龙在内的大部分物种灭亡。
    </p>
    <p class="p">
      如同其它古远的地质时代，白垩纪的岩石标志非常明显和清晰，其开始的准确时间却无法非常精确地被确定，其误差在几百万年之间。在侏㑩纪与白垩纪之间没有灭绝事件或生物演化的特点，可以明确分开两个年代。白垩纪结束的时间定的比较准，是在6550万年前左右（有科学家估计为6590万年前），那时全地球的岩石层都含大量的铱。一般以为，那时有一颗巨大的陨石撞击地球，在今墨西哥犹加敦半岛附近有一个大坑。这个陨石造成了大量生物灭绝，称为白垩纪-第三纪灭绝事件。但是这个理论有争议。
    </p>
  </div>
</template>

<script>
export default {
  name: "Cretaceous_page7",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
