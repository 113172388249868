<template>
  <el-result icon="error" title="退票失败"> </el-result>
</template>

<script>
export default {
    name:'Error'
}
</script>

<style>

</style>