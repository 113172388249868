<template>
  <el-table
    :data="filter"
    :default-sort="{ prop: 'no', order: 'ascending' }"
    style="width: 100%"
  >
    <el-table-column label="模型编号" prop="no" width="120px" sortable>
    </el-table-column>
    <el-table-column label="模型名字" prop="name" width="200px">
    </el-table-column>

    <el-table-column label="模型大小" prop="size" width="120px">
    </el-table-column>
    <el-table-column label="备注" prop="note"> </el-table-column>
    <el-table-column align="right">
      <template slot="header" slot-scope="scope">
        <el-col :span="20">
          <el-input v-model="search" size="mini" placeholder="输入关键字搜索"
        /></el-col>
        <el-col :span="3" :offset="1">
          <el-button type="primary" size="mini">新增模型</el-button></el-col
        >
      </template>
      <template slot-scope="scope">
        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
          >Edit</el-button
        >
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)"
          >Delete</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "JurassicModels",
  data() {
    return {
      tableData: [
        {
          no: 1,
          name: "Aucasaurus",
          size: "750KB",
          note: "xxx",
        },
        {
          no: 2,
          name: "Tarbosaurus",
          size: "750KB",
          note: "xxx",
        },
        {
          no: 3,
          name: "xxx",
          size: "750KB",
          note: "xxx",
        },
        {
          no: 4,
          name: "xxx",
          size: "750KB",
          note: "xxx",
        },
      ],
      search: "",
    };
  },
  computed: {
    filter() {
      return this.tableData.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style></style>
