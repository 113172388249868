<template>
  <el-table
    :data="filter"
    :default-sort="{ prop: 'id', order: 'ascending' }"
    style="width: 100%"
  >
    <el-table-column label="模型编号" prop="id" width="200px" sortable>
    </el-table-column>
    <el-table-column label="中文名字" prop="chineseName"> </el-table-column>

    <el-table-column label="英文名字" prop="englishName"> </el-table-column>
    <el-table-column align="right" width="300px">
      <template slot="header" slot-scope="scope">
        <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
      </template>
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.$index, scope.row)"
          v-if="false"
          >Edit</el-button
        >
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
//api
import { getAllDinosaur, deleteDinosaur } from "@/api/api";
import { Message } from "element-ui";
export default {
  name: "Triassic",
  data() {
    return {
      Models: [
        {
          id: "",
          chineseName: "",
          englishName: "",
          fileSize: "",
        },
      ],
      search: "",
    };
  },
  computed: {
    filter() {
      return this.Models.filter(
        (data) =>
          !this.search ||
          data.chineseName.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    handleEdit(index, row) {
      console.log("索引值", index, row);
    },
    async handleDelete(index, row) {
      this.Models.splice(index, 1);
      const token = localStorage.getItem("Token");
      let result = await deleteDinosaur(token, row.id);
      if (result.status == 200) {
        this.Models.splice(index, 0);
        Message.success({
          message: "删除成功！",
        });
      } else {
        Message.error({
          message: "删除失败！",
        });
      }
      console.log(result);
    },
  },
  async mounted() {
    const token = localStorage.getItem("Token");
    let result = await getAllDinosaur(token);
    if (result.status == 200) {
      Message.success({
        message: "获取模型数据成功！模型个数：" + result.data.length,
      });
      this.Models = result.data;
    }
    console.log(result.data);
  },
};
</script>

<style></style>
