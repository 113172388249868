<template>
  <div class="Models">
    <el-tabs type="border-card">
      <el-tab-pane label="恐龙模型"><TriassicModels /></el-tab-pane>
      <el-tab-pane label="上传模型"><UploadModel /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import TriassicModels from "@/components/Admin/items/Models/TriassicModels";
import JurassicModels from "@/components/Admin/items/Models/JurassicModels";
import CretaceousModels from "@/components/Admin/items/Models/CretaceousModels";
import UploadModel from "@/components/Admin/items/Models/UploadModel";
export default {
  name: "Models",
  components: {
    TriassicModels,
    JurassicModels,
    CretaceousModels,
    UploadModel
  },
  
};
</script>

<style scoped>
.el-tabs {
  height: 100vh;
  overflow: auto;
}
</style>
