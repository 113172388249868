<template>
  <div class="Article">
    <span class="title"
      >白垩纪简介
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      　白垩纪因欧洲西部该年代的地层主要为白垩沉积而得名。白垩纪位于侏罗纪和古近纪之间，BC1亿4500万年（误差值为400万年）前至6500万年前（误差值为30万年）。发生在白垩纪末的灭绝事件，是中生代与新生代的分界。
    </p>
    <p class="p">
      白垩纪的气候相当暖和，海平面的变化大。陆地生存着恐龙，海洋生存着海生爬行动物、菊石、以及厚壳蛤。新的哺乳类、鸟类出现，开花植物也首次出现。白垩纪-第三纪灭绝事件是地质年代中最严重的大规模灭绝事件之一，包含恐龙在内的大部分物种灭亡。
    </p>
    <p class="p">
      白垩纪这一时期形成的地层叫“白垩系”，缩写记为K，是德文的白垩纪（Kreidezeit）缩写。
    </p>
    <p class="p">
      白垩纪时期的大气层氧气含量是现今的150%，二氧化碳含量是工业时代前的6倍，气温则是高于今日4℃。
    </p>
    <p class="p">
      白垩纪是在1822年由比利时地质学家Jean d'Omalius
      d'Halloy研究巴黎盆地时所提出。其名称在拉丁文意为“黏土”，意指上白垩纪地层里常见的白垩，由海生非脊椎动物身上甲壳的碳酸钙沉积而成，尤其是球石粒。这些白垩黏土层可在欧洲大陆与不列颠群岛（尤其是著名的多佛白色峭壁）发现。
    </p>
    <p class="p">
      如同其它古远的地质时代，白垩纪的岩石标志非常明显和清晰，其开始的准确时间却无法非常精确地被确定，其误差在几百万年之间。在侏罗纪与白垩纪之间没有灭绝事件或生物演化的特点，可以明确分开两个年代。白垩纪结束的时间定的比较准确，是在BC6500万年至6600万年前结束，那时全地球的岩石层都含大量的铱。一般以为，那时有一颗巨大的陨石撞击地球，在今墨西哥犹加敦半岛留下一个大型陨石坑。这个陨石造成了大量生物灭绝，称为白垩纪-第三纪灭绝事件。但是这个理论现在有争议。
    </p>
  </div>
</template>

<script>
export default {
  name: "Cretaceous_page1",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
