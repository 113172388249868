<template>
  <div>文章管理</div>
</template>

<script>
export default {
    name:'Article'
}
</script>

<style scoped>

</style>