<template>
  <div class="SoftwareIntroduce">
    <span class="title">软件介绍
      <img src="../../images/icons/title-l.png" alt="" class="title-l">
      <img src="../../images/icons/title-r.png" alt="" class="title-r">
    </span>
    <p class="p">
      《侏罗世纪》— 重庆自然博物馆3D恐龙展示软件是一个令人兴奋和富有教育意义的应用程序。它利用全新的3D技术，将恐龙化石模型带入虚拟现实的世界中，让用户沉浸在恢宏而逼真的恐龙文明之中。这款软件为用户提供了一个完美的平台，以了解远古世界的奇迹和古老生物的壮丽。
    </p>
    <p class="p">
      作为西部地区最大的恐龙博物馆和国家级博物馆，重庆自然博物馆拥有丰富的恐龙化石藏品。然而，由于珍贵和脆弱的性质，这些化石不可能全部展示给公众观赏。通过《侏罗世纪》软件，参观者可以在不受时间和空间限制的情况下，探索重庆自然博物馆的恐龙珍品。
    </p>
    <p class="p">
      这款软件的前后端分离架构使其具有高度的性能和交互性。用户只需打开Web应用程序，就可以立即开始他们的恐龙之旅。通过令人惊叹的3D展示，用户可以近距离观察恐龙化石模型，并从各个角度欣赏它们的细节。真实感的视觉效果、逼真的动画和逼真的声音使用户仿佛置身于远古的侏罗纪时代。
    </p>
    <p class="p">
      除了引人入胜的3D展览，软件还提供了文物上报和场馆预约功能，增加了用户参与的互动性和便利性。文物上报功能为用户提供了一个平台，让他们向博物馆提供重要的文物发现信息。用户可以记录文物的种类、发现时间和地点等细节，并与博物馆方面分享这些宝贵的发现。这种参与式的文化保护意识有助于传播文化遗产的重要性，并促进公众对保护文物的共同责任的认识。
    </p>
    <p class="p">
      软件背后的后台管理系统是该项目的核心。这个系统集中管理恐龙模型、文物上报信息和场馆预约信息，确保数据的准确性和安全性。管理员可以使用后台系统轻松管理展示内容，审核文物上报信息，并监控场馆预约情况。这个强大而直观的管理系统为博物馆提供了便捷的工具，以确保软件的运营和管理的顺利进行。
    </p>
    <p class="p">
      《侏罗世纪》— 重庆自然博物馆3D恐龙展示软件是一款引人入胜的应用程序，它带给用户沉浸式的恐龙体验，让他们感受恐龙文明的神秘和壮美。通过创新的3D技术，用户可以近距离欣赏恐龙化石的细节，并通过文物上报和场馆预约功能参与到文化保护和历史遗产的传承中来。这个软件为重庆自然博物馆的参观者提供了一个全新的方式来鉴赏和学习恐龙的历史，同时为博物馆提供了更高效的管理工具，使之成为地区文化传播和教育的重要平台。
    </p>
  </div>
</template>

<script>
export default {
  name: "SoftwareIntroduce",
};
</script>

<style scoped>
.SoftwareIntroduce {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l{
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r{
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}

</style>
