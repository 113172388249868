<template>
  <div class="body">
    <div id="login_outer" ref="loginbox">
      <h1>用户登录</h1>
      <form action="" id="btn">
        <div class="line">
          <div class="inputbox">
            <input
              type="text"
              id="username"
              placeholder="请输入用户名"
              v-model="userName"
            />
          </div>
        </div>
        <div class="line">
          <div class="inputbox">
            <input
              type="password"
              id="password"
              placeholder="请输入密码"
              v-model="passWord"
              @keydown.enter="handleLogin"
            />
          </div>
        </div>
        <div class="line" ref="login">
          <input id="go" type="button" value="登录" @click="handleLogin" />
        </div>
        <span id="about">关于我们</span>
      </form>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/api";
export default {
  name: "Login",
  data() {
    return {
      userName: "123",
      passWord: "123",
    };
  },
  methods: {
    async handleLogin() {
      let formdata = new FormData();
      formdata.append("username", this.userName);
      formdata.append("password", this.passWord);
      let result = await login(formdata);
      if (result.status == 200) {
        localStorage.setItem("Token", result.data);
        this.$router.push({
          name: "AdminHome",
        });
      } else {
        alert("登录失败");
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.body {
  height: 100vh;
  width: 100vw;
}

.body {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  background-image: url(../../images/background/bg.jpg);
}

#logo {
  position: fixed;
  left: 30px;
  top: 20px;
  height: 100px;
}

#login_outer {
  width: 450px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  background-color: rgba(255,255,255,1);
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.4), -3px -3px 4px rgba(0, 0, 0, 0.1),;
  border-radius: 15px;
  padding: 30px;
}

h1 {
  margin-bottom: 30px;
  color: rgb(30, 65, 130);
  text-align: center;
}

.line {
  height: 40px;
  margin-bottom: 30px;
  text-align: right;
}

input {
  height: 40px;
  width: 100%;
  vertical-align: text-bottom;
  outline: none;
  border-radius: 20px;
  border: 0;

  border: 1px solid #ccc;
  text-align: center;
  color: #303133;
  font-size: 18px;
  transition: 1s;
  background-color: #fff;
}

input:focus {
  background-color: rgb(237, 236, 234);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#getphone {
  width: 180px;
  margin-right: 10px;
}

#go {
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: rgb(30, 65, 130);
  border: 0;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgb(41, 95, 171);
  transition: 0.5s;
}

#go:hover {
  cursor: pointer;
  box-shadow: 0 8px 20px 0 rgb(30, 65, 130);
}

/* 用户名提示框 */
.inputbox {
  position: relative;
  display: flex;
  text-align: center;
  width: 100%;
  align-items: center;
}

#about {
  color: rgb(30, 65, 130);
  cursor: pointer;
  transition: 0.5s;
}

#about:hover {
  opacity: 0.5;
}
</style>
