<template>
  <div class="Article">
    <span class="title"
      >白垩纪的地理特征
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      在白垩纪初期，冈瓦那大陆仍未分裂，而后南美洲、南极洲、澳大利亚相继脱离非洲，印度和马达加斯加还连在非洲上。南大西洋与印度洋开始出现。这些板块运动，造成大量的海底山脉，进而造成全球性的海平面上升。非洲北边的特提斯洋在变窄。西部内陆海道将北美洲分为东西两部，这个海道在白垩纪后期缩小，留下厚的海相沉积层，夹杂者煤矿床。在白垩纪的海平面最高时期，地表上有1/3的陆地沉浸于海洋之下。
    </p>
    <img src="./images/图片2.jpg" alt="" class="img">
    <p class="p">
      白垩纪因为黏土层而著名，这个时期形成的黏土层多于显生宙的其他时期。中洋脊的火山活动，或是海底火山附近的海水流动，使白垩纪的海洋富含钙，接近饱和，也使得钙质微型浮游生物的数量增加。分布广泛的碳酸盐与其他沉积层，使得白垩纪的岩石纪录特别多。北美洲的著名地层组包含：堪萨斯州的海相烟山河黏土层、晚期的陆相海尔河组。其他的著名白垩纪地层包含：欧洲的威尔德（Weald）、亚洲的义县组。白垩纪末期到古新世早期，印度发生大规模火山爆发，形成的德干地盾。
    </p>
    <p class="p">
      白垩纪时，南美洲与非洲大陆之间的裂谷迅速张开形成南大西洋，到末期已加宽到约3000千米。北大西洋裂谷位于格陵兰和北美东侧，随着北美洲向西漂移，裂谷在扩大。特提斯海把欧亚大陆与非洲分开，中南欧和中近东的许多国家当时都处于海侵中。白垩纪时气候比较温暖，未见极地冰盖迹象。当时大部分地区雨量充沛，气候湿润，近海及滨海地带形成丰富的石油、煤、天然气和油页岩矿床，如美国的得克萨斯州、墨西哥、波斯湾、北非和俄罗斯的许多大油田，又如中国松辽平原上白垩统的大庆油田，东北和内蒙古下白垩统的许多大煤田。在一些气候干旱炎热的地区，如中国南方晚白垩世的西南湖群和云梦泽水系，有巨厚的膏盐矿床沉积。此外，在云南白垩纪红层中，含铜砂矿床品位高，规模大，易于开采和冶炼，是中国有名的铜乡。
    </p>
  </div>
</template>

<script>
export default {
  name: "Cretaceous_page6",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
