<template>
  <div class="UploadModel">
    <el-upload
      class="upload-demo"
      drag
      action="#"
      :multiple="false"
      accept="zip"
      :file-list="File"
      :limit="1"
      :on-change="intoFile"
      ref="upload1"
      :auto-upload="false"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        将文件<em>拖到此处</em> 或 <em>点击选取文件</em>
        <div class="el-upload__tip" slot="tip">
          只能上传一个zip/rar文件，且不超过200MB
        </div>
      </div>
    </el-upload>

    <el-form ref="form" :model="form" label-width="80px" style="width: 600px">
      <el-form-item label="中文名称">
        <el-input v-model="form.ChineseName"></el-input>
      </el-form-item>
      <el-form-item label="英文名称">
        <el-input v-model="form.EnglishName"></el-input>
      </el-form-item>
      <el-form-item label="模型图片">
        <el-upload
          class="upload-demo"
          ref="upload2"
          action="#"
          :show-file-list="true"
          :on-change="intoFile2"
          :auto-upload="false"
          :multiple="false"
          :limit="1"
          list-type="picture-card"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="介绍图片">
        <el-upload
          class="avatar-uploader"
          ref="upload3"
          action="#"
          :show-file-list="true"
          :on-change="intoFile3"
          :auto-upload="false"
          :multiple="false"
          :limit="1"
          list-type="picture-card"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="模型种类">
        <el-select v-model="form.Kinds" placeholder="请选择模型种类">
          <el-option label="蜥脚类恐龙" value="蜥脚类恐龙"></el-option>
          <el-option label="兽脚类恐龙" value="兽脚类恐龙"></el-option>
          <el-option label="鸟脚类恐龙" value="鸟脚类恐龙"></el-option>
          <el-option label="角龙类恐龙" value="角龙类恐龙"></el-option>
          <el-option label="剑龙类恐龙" value="剑龙类恐龙"></el-option>
          <el-option label="甲龙类恐龙" value="甲龙类恐龙"></el-option>
          <el-option label="肿头类恐龙" value="肿头类恐龙"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="恐龙时代">
        <el-radio-group v-model="form.YearsName">
          <el-radio label="三叠纪"></el-radio>
          <el-radio label="侏罗纪"></el-radio>
          <el-radio label="白垩纪"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="介绍信息">
        <el-input type="textarea" v-model="form.IntroduceInfo"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleUpload">立即上传</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
import { uploadDinosaur } from "@/api/api";
import { Message } from "element-ui";
export default {
  name: "UploadModel",
  data() {
    return {
      File: [],
      file: "",
      file2: "",
      file3: "",
      form: {
        ChineseName: "", //中文名
        EnglishName: "", //英文名
        Kinds: "", //恐龙种类
        YearsName: "", //恐龙时代
        IntroduceInfo: "", //介绍信息
      },
    };
  },
  methods: {
    intoFile(file, fileList) {
      this.file = file.raw;
      console.log(this.file);
    },
    intoFile2(file, fileList) {
      this.file2 = file.raw;
      console.log(this.file2);
    },
    intoFile3(file, fileList) {
      this.file3 = file.raw;
      console.log(this.file3);
    },
    async handleUpload() {
      const token = localStorage.getItem("Token");
      const formdata = new FormData();
      console.log(this.file);
      formdata.append("model", this.file);
      formdata.append("modelPicture", this.file2);
      formdata.append("introducePicture", this.file3);
      formdata.append("ChineseName", this.form.ChineseName);
      formdata.append("EnglishName", this.form.EnglishName);
      formdata.append("Kinds", this.form.Kinds);
      formdata.append("YearsName", this.form.YearsName);
      formdata.append("IntroduceInfo", this.form.IntroduceInfo);
      let result = await uploadDinosaur(token, formdata);
      if (result.status == 200) {
        Message.success({
          message: "添加成功！",
        });
        this.$refs.upload1.clearFiles()
        this.$refs.upload2.clearFiles()
        this.$refs.upload3.clearFiles()
        this.form = {
          ChineseName: "", //中文名
          EnglishName: "", //英文名
          Kinds: "", //恐龙种类
          YearsName: "", //恐龙时代
          IntroduceInfo: "", //介绍信息
        };
      } else {
        Message.error({
          message: "添加失败！",
        });
        console.log(result);
      }
    },
  },
};
</script>

<style scoped>
.UploadModel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upload-demo {
  margin: 30px 0;
}
</style>
