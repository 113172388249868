<template>
  <el-result icon="success" title="退票成功"> </el-result>
</template>

<script>
export default {
    name:'Success'
}
</script>

<style>

</style>