<template>
  <div class="images_outer" v-if="dataList.length > 0">
    <el-skeleton
      style="width: 100%"
      :loading="loading"
      animated
      :count="dataList.length"
      class="images_outer"
      v-if="loading"
    >
      <template slot="template">
        <el-card :body-style="{ padding: '0px' }" shadow="always">
          <el-skeleton-item
            variant="image"
            style="width: 210px; height: 120px"
          />
          <div style="padding: 14px">
            <div style="height: 20.8px">
              <el-skeleton-item variant="text" style="height: 20.8px" />
            </div>
            <div class="bottom clearfix">
              <el-button type="text" class="button">点击查看</el-button>
            </div>
          </div>
        </el-card>
      </template>
    </el-skeleton>
    <el-card
      :body-style="{ padding: '0px' }"
      v-for="(data, index) in dataList"
      :key="index"
      shadow="always"
      v-show="!loading"
    >
      <img
        :src="`${$public.ip}/DinosaurModelPicture/${data.modelPicture}`"
        style="width: 210px; height: 120px"
        class="image"
        @load="load"
      />
      <div style="padding: 14px">
        <span>{{ data.chineseName }}</span>
        <div class="bottom clearfix">
          <el-button
            type="text"
            class="button"
            @click="toRoute(data.yearsName, data.chineseName)"
            >点击查看</el-button
          >
        </div>
      </div>
    </el-card>
  </div>
  <el-empty v-else></el-empty>
</template>

<script>
import { getDinosaurs } from "@/api/api";
export default {
  name: "Ornithischids",
  data() {
    return {
      dataList: [],
      loading: true,
      imagesCount: 0,
    };
  },
  async mounted() {
    let result = await getDinosaurs("鸟脚类恐龙");
    console.log("鸟脚类：", result);
    if (result.status == 200) {
      this.dataList = result.data;
    }
  },
  methods: {
    toRoute(yearsName, name) {
      console.log(yearsName, name);
      if (yearsName == "三叠纪") {
        this.$router.push({
          name: "Triassic",
          params: {
            name: name,
          },
        });
      } else if (yearsName == "侏罗纪") {
        this.$router.push({
          name: "Jurassic",
          params: {
            name: name,
          },
        });
      } else {
        this.$router.push({
          name: "Cretaceous",
          params: {
            name: name,
          },
        });
      }
    },
    load() {
      this.imagesCount++;
      if (this.imagesCount == this.dataList.length) {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.images_outer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
img {
  border-bottom: 1px solid #ebeef5;
  display: block;
}
.el-card.is-always-shadow {
  margin: 10px;
}
</style>
