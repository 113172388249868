//api接口统一管理
//引入二次封装后的axios函数
import requests from "./request";

//登录
export const login = (formdata) =>
  requests({
    url: "Dinosaur/login",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formdata,
  });

//订票
export const bookTickets = (dataObj) =>
  requests({
    url: "Ticket/bookTickets",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataObj),
  });

//获得验证码
export const sendVerificationCode = (telPhone) =>
  requests({
    url: "Ticket/sendVerificationCode",
    method: "post",
    params: {
      telPhone: telPhone,
    },
  });
//查询票信息
export const checkTicket = (telPhone) =>
  requests({
    url: "Ticket/checkTicket",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(telPhone),
  });
//退票
export const returnTicket = (ticketId) =>
  requests({
    url: "Ticket/returnTicket",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(ticketId),
  });
//获得所有模型
export const getAllDinosaur = (token) =>
  requests({
    url: "Dinosaur/getAllDinosaur",
    method: "get",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

//文物申报
export const CulturalRelic = (formdata) =>
  requests({
    url: "CulturalRelic/applicateCR",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formdata,
  });

export const getCR = (token, id) =>
  requests({
    url: "CulturalRelic/getCR",
    method: "post",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      Id: id,
    },
  });
//上传模型
export const uploadDinosaur = (token, formdata) =>
  requests({
    url: "Dinosaur/uploadDinosaur",
    method: "post",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
    data: formdata,
  });
//删除模型
export const deleteDinosaur = (token, id) =>
  requests({
    url: "Dinosaur/deleteDinosaur",
    method: "post",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(id),
  });
//获取恐龙列表
export const getDinosaurs = (className) =>
  requests({
    url: "Dinosaur/getDinosaurs",
    method: "get",
    params: {
      className: className,
    },
  });

//获取所有还未使用的票
export const getAllTickets = (token) =>
  requests({
    url: "Ticket/getAllTickets",
    method: "get",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

//改变票的状态
export const changeTicketState = (token, Id) =>
  requests({
    url: "Ticket/changeTicketState",
    method: "post",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(Id),
  });

//获取所有申报数据
export const getAllCR = (token) =>
  requests({
    url: "CulturalRelic/getAllCR",
    method: "get",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

//用中文名获取恐龙
export const getDinosaurbyName = (name) =>
  requests({
    url: "Dinosaur/getDinosaurbyName",
    method: "get",
    params: {
      ChineseName: name,
    },
  });
