//二次封装axios
import axios from "axios";
// 定义一个变量来保存上一次请求的时间戳
const requests = axios.create({
  baseURL: "http://39.98.67.182:8888/api/", //给请求自动添加/api路径
  timeout: 5000, //超时时间 5s
});
//请求拦截器 在发请求之前，请求拦截器可以监测到，可以在请求发出去之前做一些事情
//config配置对象，里面有一个很重要的属性headers请求头
requests.interceptors.request.use((config) => {
  // 更新上一次请求的时间戳为当前时间
  return config;
});

//响应拦截器 服务器响应数据回来以后，响应拦截器可以检测到，可以做一些事情
requests.interceptors.response.use(
  //成功回调
  (res) => {
    return res;
  },
  //失败回调
  (error) => {
    console.error("请求失败");
    return error.response;
  }
);

export default requests;
