<template>
  <div class="AdminHome">
    <!-- 左边的菜单栏 -->
    <div class="AsideNav">
      <div class="Navigator">
        <div class="logo">
          <img src="../../images/logos/logo.png" alt="" />
        </div>
        <div class="Navigation">
          <div class="nav" @click="goRoute('Models')">模型管理</div>
          <!-- <div class="nav" @click="goRoute('Article')">文章管理</div> -->
          <div class="nav" @click="goRoute('Tickets')">票务管理</div>
          <div class="nav" @click="goRoute('Report')">申报管理</div>
        </div>
      </div>
    </div>
    <div class="Main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminHome",
  data() {
    return {
    }
  },
  methods: {
    goRoute(name) {
      this.$router.replace({
        name: name,
      });
    },
  },
};
</script>

<style scoped>
.AdminHome {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.AsideNav {
  width: 180px;
}
.Main {
  width: calc(100vw - 180px);
  background: red;
}
/* 导航栏 */
.Navigator {
  width: 180px;
}
.logo {
  width: 180px;
}
.logo > img {
  width: 100%;
  display: block;
}

.Navigator > .Navigation > .nav {
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.nav:hover {
  background-color: #ccc;
}
</style>
