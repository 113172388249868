<template>
  <el-table
    :data="filter"
    :default-sort="{ prop: 'id', order: 'ascending' }"
    style="width: 100%"
    v-loading="loading"
  >
    <el-table-column label="游客票号" prop="id" width="120px" sortable>
    </el-table-column>
    <el-table-column label="游客姓名" prop="name" width="200px">
    </el-table-column>
    <el-table-column label="游客身份证号" prop="userId" width="200px">
    </el-table-column>

    <el-table-column label="参观时间" prop="palyTime" width="200px">
    </el-table-column>

    <el-table-column label="参观场次" prop="session">
      <template slot-scope="scope">
        <el-tag>{{ scope.row.session == 0 ? "上半场" : "下半场" }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column align="right" min-width="200px">
      <template slot="header" slot-scope="scope">
        <el-col :span="10" :offset="14">
          <el-input v-model="search" size="mini" placeholder="输入游客姓名搜索"
        /></el-col>
      </template>
      <template slot-scope="scope">
        <el-switch
          ref="switch"
          v-model="scope.row.ticketState"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="生效"
          inactive-text="失效"
          @change="change(scope.row.id)"
        >
        </el-switch>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { getAllTickets, changeTicketState } from "@/api/api";
export default {
  name: "Adult",
  data() {
    return {
      loading: true,
      tableData: [
       
      ],
      search: "",
    };
  },

  computed: {
    filter() {
      return this.tableData.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    async change(Id) {
      console.log(Id);
      const token = localStorage.getItem("Token");
      let result = await changeTicketState(token, Id);
      console.log(result);
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
  async mounted() {
    const token = localStorage.getItem("Token");
    let result = await getAllTickets(token);
    if (result) {
      this.loading = false;
      this.tableData = result.data.ticket;
    }
    console.log(result);
  },
};
</script>

<style></style>
