<template>
  <div class="Article">
    <span class="title"
      >侏罗纪的气候、矿产与生物
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      　这时候全球各地的气候都很温暖,涌入裂缝而生成的海洋产生湿润的风,给内陆的沙漠带来雨量。植物延伸至从前不毛的地方,提供分布广泛且数量众多的恐龙(包括最大型的陆上动物)所需的食物。在他们的上空飞翔最早的小型鸟类;这些鸟类可能是由小型的恐龙演化而来。海洋则是由大型、会游泳的新爬行类和已具[现代]线条的硬骨鱼类所共享。
    </p>
    <p class="p">
      气候较现代温暖和均一,但也存在热带、亚热带和温带的区别。早、中侏罗世以蒸发岩、风成沙丘为代表的干旱气候带出现于联合古陆中西部的北美南部、南美和非洲,晚侏罗世时扩展到亚洲中南部。中国南部,早侏罗世时处于热带-亚热带湿润气候环境,中晚侏罗世逐渐变为炎热干旱环境;中国北部,早、中侏罗世气候温暖潮湿,晚侏罗世温暖潮湿地区缩小。环太平洋带的强烈构造变动与太平洋板块向周围大陆板块的俯冲密切相关。伴随着构造运动的强烈岩浆活动形成钨、锡、钼、铅、锌、铜、铁等矿产,成为太平洋金属成矿带主体部分。
    </p>

    <p class="p">侏罗纪-生物概况</p>
    <img src="./images/图片2.jpg" alt="" class="img" />
    <p class="p">
      　侏罗纪是恐龙的鼎盛时期,在三叠纪出现并开始发展的恐龙已迅速成为地球的统治者。各类恐龙济济一堂,构成一幅千姿百态的龙的世界。当时除了陆上的身体巨大的雷龙、梁龙等,水中的鱼龙和飞行的翼龙等也大量发展和进化。
    </p>

    <p class="p">
      大型的兽脚类猎食草食性动物;而小型的兽脚类,如空骨龙类和细颚龙类等则追捕小型猎物,也可能以腐肉为食。
    </p>
    <p class="p">昆虫</p>
    <p class="p">
      侏罗纪的昆虫更加多样化,大约有一千种以上的昆虫生活在森林中及湖泊、沼泽附近。除原已出现的蟑螂、蜻蜓类、甲虫类外,还有蛴螬类、树虱类、蝇类和蛀虫类。这些昆虫绝大多数都延续生存到现代。
    </p>
    <p class="p">植物</p>
    <p class="p">
      智利松的近亲-针叶林,突出于树蕨、棕榈状拟苏铁类和苏铁类所组成的大森林。地面上长满了蕨类和木贼所构成的浓密植被。
    </p>
    <p class="p">
      在侏罗纪的植物群落中,裸子植物中的苏铁类,松柏类和银杏类极其繁盛。蕨类植物中的木贼类、真蕨类和密集的松、柏与银杏及乔木羊齿类共同组成茂盛的森林,草本羊齿类和其它草类则遍布低处,覆掩地面。在比较干燥的地带,生长着苏铁类和羊齿类,形成广阔常绿的原野。侏罗纪之前,地球上的植物分区比较明显,由于迁移和演变,侏罗纪植物群的面貌在地球各区趋于近似,说明侏罗纪的气候大体上是相近的。
    </p>
    <p class="p">空中的生物</p>
    <p class="p">
      具有皮质翅膀的翼龙类是空中的优势生物。早期的鸟类也出现、最著名的就是始组鸟,拥有与小型兽脚类相似的骨骷、牙齿和爪子,但也有长羽毛的翅膀和尾巴,并且能够飞翔。
    </p>
    <p class="p">鸟类</p>
    <p class="p">
      鸟类的出现则代表了脊椎动物演化的又一个重要事件。1861年在德国巴伐利亚州索伦霍芬晚侏罗纪地层中发现的“始祖鸟(Archaeopteryx)”化石被公认为是最古老的鸟类代表;近年来,我国古生物学家在辽宁发现的“中华龙鸟(Sinosauropteryx)化石得到了国际学术界的广泛关注,为研究羽毛的起源、鸟类的起源和演化提供了新的重要材料。伴随着鸟类的出现,脊椎动物首次占据了陆、海、空三大生态领域。
    </p>
    <p class="p">水中的生物</p>
    <p class="p">
      伪龙类和板齿龙类都绝种,但鱼龙存活了下来,生活在浅海中的动物还有一群四肢己演化成鳍形肢的海鳄类和硬骨鱼类。其他的海洋生物还有蛇颈龙和短龙。到了晚期,鱼龙和海鳄类逐渐步向衰亡。
    </p>
  </div>
</template>

<script>
export default {
  name: "Jurassic_page2",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
