<template>
  <div class="CenturyArchives">
    <Header />
    <div class="banner">
      <img src="../images/background/Banner1.jpg" alt="" />
    </div>
    <div class="webAdress">
      <div>
        <img src="../images/icons/401位置.png" alt="" /> 位置：世纪档案/{{
          this.$route.meta.nickname
        }}
      </div>
    </div>
    <!-- 主体内容 -->
    <div class="Main-Container">
      <div class="left_nav">
        <div class="nav_outer">
          <el-menu
            :default-active="$route.name"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
          >
            <el-menu-item index="Big" @click="goRoute('Big')">
              <span slot="title">恐龙大事纪</span>
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">
                <span>三叠纪档案</span>
              </template>
              <el-menu-item
                index="2-1"
                @click="goRoute('Articles', 'Triassic_page1')"
                >三叠纪概要</el-menu-item
              >
              <el-menu-item index="2-2" @click="goRoute('Articles','Triassic_page2')"
                >三叠纪环境</el-menu-item
              >
              <el-menu-item index="2-3" @click="goRoute('Articles','Triassic_page3')"
                >三叠纪复苏</el-menu-item
              >
              <el-menu-item index="2-4" @click="goRoute('Articles','Triassic_page4')"
                >一个转折</el-menu-item
              >
              <el-menu-item index="2-5" @click="goRoute('Articles','Triassic_page5')"
                >岩石年代测定和化石记录</el-menu-item
              >
              <el-menu-item index="2-6" @click="goRoute('Articles','Triassic_page6')"
                >三叠纪大灭绝</el-menu-item
              >
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <span>侏罗纪档案</span>
              </template>
              <el-menu-item index="3-1" @click="goRoute('Articles','Jurassic_page1')"
                >侏罗纪简介</el-menu-item
              >
              <el-menu-item index="3-2" @click="goRoute('Articles','Jurassic_page2')"
                >侏罗纪气候</el-menu-item
              >
              <el-menu-item index="3-3" @click="goRoute('Articles','Jurassic_page3')"
                >侏罗纪名称由来</el-menu-item
              >
              <el-menu-item index="3-4" @click="goRoute('Articles','Jurassic_page4')"
                >地层划分及重大事件</el-menu-item
              >

            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <span>白垩纪档案</span>
              </template>
              <el-menu-item index="4-1" @click="goRoute('Articles','Cretaceous_page1')"
                >白垩纪简介</el-menu-item
              >
              <el-menu-item index="4-2" @click="goRoute('Articles','Cretaceous_page2')"
                >白垩纪亚层</el-menu-item
              >
              <el-menu-item index="4-3" @click="goRoute('Articles','Cretaceous_page3')"
                >白垩纪物种分布</el-menu-item
              >
              <el-menu-item index="4-4" @click="goRoute('Articles','Cretaceous_page4')"
                >白垩纪气候环境</el-menu-item
              >
              <el-menu-item index="4-5" @click="goRoute('Articles','Cretaceous_page5')"
                >白垩纪地质结构</el-menu-item
              >
              <el-menu-item index="4-6" @click="goRoute('Articles','Cretaceous_page6')"
                >白垩纪地理特征</el-menu-item
              >
              <el-menu-item index="4-7" @click="goRoute('Articles','Cretaceous_page7')"
                >大灭绝事件</el-menu-item
              >

            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="right_content">
        <router-view></router-view>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header";
import Footer from "@/components/common/Footer";
export default {
  name: "CenturyArchives",
  components: {
    Header,
    Footer,
  },
  methods: {
    goRoute(name, articalName) {
      this.$router.push({
        name: name,
        query: {
          articalName: articalName,
        },
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    toRoute() {
      this.$router.push({
        name: "Articles",
      });
    },
  },
};
</script>

<style scoped>
/* 改变导航样式 */
.nav {
  min-width: 1400px;
  background-color: rgba(0, 0, 0, 0.1);
}
/*  */
.banner {
  position: relative;
  min-width: 1400px;
}
.banner > img {
  width: 100%;
}
.webAdress {
  margin: 30px 0;
}

.webAdress div {
  height: 20px;
  width: 1400px;
  margin: 0 auto;
}
.webAdress div > img {
  height: 20px;
  vertical-align: bottom;
}
.Main-Container {
  width: 1400px;
  min-height: 600px;
  background: #fff;
  margin: 60px auto;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

/* 左侧导航 */
.left_nav {
  width: 205px;
  background: #fff;
  margin-right: 10px;
}
.left_nav > .nav_outer {
  border-top: 4px solid #334d11;
}
.left_nav > .nav_outer > .navItem {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #666666;
  transition: 0.2s;
  cursor: pointer;
}
.left_nav > .nav_outer > .navItem:hover {
  background-color: #334d11;
  color: #fff;
}
.right_content {
  width: 1200px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
}
.right_content > .Title > h1 {
  text-align: center;
}
</style>
