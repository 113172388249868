<template>
  <div class="Article">
    <span class="title"
      >二叠纪-三叠纪大灭绝
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      三叠纪的开始同样始于一个差不多规模的玄武岩喷发，位于俄罗斯东部的特纳普超级火山（Siberian
      Traps）。特纳普超级火山和中大西洋岩浆省的火山活动产生了数百万立方公里的熔岩。尽管这些熔岩，和今天的冰岛火山一样，是从火山口冒出。而不是致命的普林尼式火山喷发（Plinian-type
      volcanoes），但同样具有毁灭性。
    </p>
    <p class="p">
      火山爆发时，摧枯拉朽的熔岩流会毁掉沿途所有的生命。此外，爆炸性喷发中喷涌的火山碎屑，比如大量的火山灰和熔岩弹，也是致命的。然而，更重要的还有爆发时喷出的气体，主要是二氧化硫，二氧化碳和甲烷。后两种气体是温室气体的重要组成部分。从二叠纪-三叠纪界限处的岩石剖面中获得的同位素证据表明，大约在温度上升了15摄氏度时，曾出现过一个快速的升温事件。
    </p>
    <p class="p">
      全球变暖带来了许多后果。陆地和海洋中的生物，要么死亡，要么向极地迁徙。持续的变暖使热带海洋的温度攀升到了40摄氏度。在陆地上，火山气体的混合物不仅造成了气候变暖，而且还和雨水混合形成酸雨。酸雨杀死了树木，植被的丧失导致大量地表裸露出来，陆地上的岩屑在侵蚀作用下被冲进浅海。此外，全球变暖压低了温跃层（thermoclines），扰乱了大洋环流。目前已有充足的证据表明当时全球范围内的海洋缺氧。
    </p>
    <p class="p">
      陆上的土壤流失，海底缺氧，以及急剧的变暖这些让生命难以承受的极端事件导致约有90-95%的物种在几年或几十年间全部灭绝了。这种规模的灭绝是前所未有的，即使是发生在后来三叠纪末期和白垩纪末期的恐龙大灭绝，也达不到如此程度。
    </p>
  </div>
</template>

<script>
export default {
  name: "Triassic_page6",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>