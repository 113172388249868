<template>
  <div class="Yunbo">
    <Header />
    <!-- banner -->
    <div class="banner">
      <img src="../images/background/Banner1.jpg" alt="" />
    </div>
    <div class="webAdress">
      <div>
        <img src="../images/icons/401位置.png" alt="" /> 位置：云博概况/{{
          $route.meta.nickname
        }}
      </div>
    </div>
    <!-- 主体内容 -->
    <div class="Main-Container">
      <div class="left_nav">
        <div class="nav_outer">
          <div class="navItem" @click="toRoute('SoftwareIntroduce')">
            侏罗纪世纪软件介绍
          </div>
          <div class="navItem" @click="toRoute('DeveloperTeam')">
            开发者团队
          </div>
          <div class="navItem" @click="toRoute('DeveloperCradle')">
            开发者摇篮
          </div>
          <div class="navItem" @click="toRoute('Statement')">责任声明</div>
        </div>
      </div>
      <div class="right_content" ref="right_content">
        <router-view></router-view>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header";
import Footer from "@/components/common/Footer";
export default {
  name: "Yunbo",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      content: ["第一段", "第二段"],
    };
  },
  mounted() {},
  methods: {
    toRoute(name) {
      this.$router.push({
        name: name,
      });
    },
  },
};
</script>

<style scoped>
/* 改变导航样式 */
.nav {
  min-width: 1400px;
  background-color: rgba(0, 0, 0, 0.5);
}
.navb a::before {
  background-color: #374828;
}
/*  */

.webAdress {
  margin: 30px 0;
}

.webAdress div {
  height: 20px;
  width: 1400px;
  margin: 0 auto;
}
.webAdress div > img {
  height: 20px;
  vertical-align: bottom;
}

.banner {
  position: relative;
  min-width: 1400px;
}
.banner > img {
  width: 100%;
}
.Main-Container {
  width: 1400px;
  min-height: 600px;
  background: #fff;
  margin: 0 auto;
  display: flex;
  margin-bottom: 80px;
}

/* 左侧导航 */
.left_nav {
  width: 190px;
  background: #fff;
  margin-right: 10px;
}
.left_nav > .nav_outer {
  border-top: 4px solid #334d11;
}
.left_nav > .nav_outer > .navItem {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #666666;
  transition: 0.2s;
  cursor: pointer;
}
.left_nav > .nav_outer > .navItem:hover {
  background-color: #334d11;
  color: #fff;
}
.right_content {
  width: 1200px;
  border: 1px solid #e5e5e5;
  padding: 10px 15px;
}
.right_content > .Title > h1 {
  text-align: center;
}
</style>
