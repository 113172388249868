<template>
  <div class="Article">
    <span class="title"
      >侏罗纪的名称由来
      <img src="../../../../images/icons/title-l.png" alt="" class="title-l" />
      <img src="../../../../images/icons/title-r.png" alt="" class="title-r" />
    </span>
    <p class="p">
      　侏罗纪之名称源于瑞士、法国交界的侏罗山(今译汝拉山),是法国古生物学家A.布朗尼亚尔于1829年提出的。由于欧洲侏罗系岩性具有明显的三分性,1837年,L.von布赫将德国南部侏罗系分为下、中、上3部分。1843年,F.A.昆斯泰德则将下部黑色泥灰岩称黑侏罗,中部棕色含铁灰岩称棕侏罗,上部白色泥灰岩称白侏罗。侏罗纪分早、中、晚3个世纪。 
    </p>
    <img src="./images/图片3.jpg" alt="" class="img">
  </div>
</template>

<script>
export default {
  name: "Jurassic_page3",
};
</script>

<style scoped>
.Article {
  padding: 15px;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 26px;
  position: relative;
}
.title-l {
  position: absolute;
  left: -50px;
  top: 0;
  height: 100%;
}
.title-r {
  position: absolute;
  right: -50px;
  top: 0;
  height: 100%;
}
.p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 25px;
  margin: 10px;
  color: #2d2d2d;
  text-align: left;
}
.img {
  height: 400px;
}
</style>
