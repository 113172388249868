<template>
  <div class="JurassicArchives">侏罗纪档案</div>
</template>

<script>
export default {

}
</script>

<style>

</style>