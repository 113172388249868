<template>
  <div class="block1">
    <!-- Topbox -->
    <transition
      name="animate__animated animate__bounce"
      appear
      enter-active-class="animate__fadeInUp"
    >
      <div class="Topbox">
        <div class="item" @click="toRoute('Triassic')">
          <img src="../../images/icons/鱼.png" alt="" />
          <div class="name">
            <p>三叠纪</p>
            <p>Triassic period</p>
          </div>
        </div>
        <div class="item" @click="toRoute('Jurassic')">
          <img src="../../images/icons/恐龙.png" alt="" />
          <div class="name">
            <p>侏罗纪</p>
            <p>Jurassic</p>
          </div>
        </div>
        <div class="item" @click="toRoute('Cretaceous')">
          <img src="../../images/icons/蜥蜴.png" alt="" />
          <div class="name">
            <p>白垩纪</p>
            <p>Cretaceous Period</p>
          </div>
        </div>
      </div>
    </transition>

    <!-- -------- -->
    <!-- 展示内容 -->
    <div class="block1-1">
      <div class="block1-1-l">
        <transition
          name="animate__animated animate__bounce"
          appear
          enter-active-class="animate__fadeInUp"
        >
          <video src="./video/三叠纪.mp4" poster="" controls></video>
        </transition>
      </div>
      <div class="block1-1-r">
        <div class="left" v-for="(item, index) in dataArr" :key="index">
          <h2>{{item.name}}</h2>
          <p>
            {{item.info}}
          <div class="year">
            <div class="item">
              <!-- <p>2040年</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block1-2">
      <div class="block11-2-l-tab">
        <div class="block1-2-l" id="VR">
          <div class="title">
            <h1>Museum<br />Of<br />Nature</h1>
          </div>
          <div class="l-bottom">
            <div class="l-bottom-line">
              <div class="linetext">
                <h2>Museum&nbsp;Of&nbsp;Nature</h2>
              </div>
            </div>
            <div class="l-bottom-content">
              <h1>重庆自然博物馆</h1>
              <p>
                重庆自然博物馆是一所综合性自然科学博物馆，旧馆位于重庆市枇杷山正街74号，新馆位于重庆市北碚区金华路398号。前身为1930年卢作孚先生创办的“中国西部科学院”，以及1943年由十余家全国性学术机构联合组建的“中国西部博物馆”。1949年以后，先后改建为西南人民科学馆、西南博物院自然博物馆、重庆市博物馆自然部。1981年改为现名，为国家一级博物馆。
              </p>
              <div class="content-img">
                <div class="img">
                  <img src="../../images/block1-2/博物馆1.jpg" alt="" />
                </div>
                <div class="img">
                  <img src="../../images/block1-2/博物馆2.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block1-2-r">
        <div class="item" id="item1" style="" @click="toBo">
          <img src="../../images/icons/博物馆.png" alt="" />
          <p>重庆自然博物馆</p>
          <p style="font-size: 14px; color: white">国家一级博物馆</p>
          <p style="font-size: 14px; color: white">综合性自然科学博物馆</p>
        </div>
        <div class="item" id="item2" @click="toRoute('Triassic')">
          <img src="../../images/icons/鱼.png" alt="" />
          <p>三叠纪</p>
          <p style="font-size: 14px; color: white">三世纪之初</p>
          <p style="font-size: 14px; color: white">恐龙文明的起源时期</p>
        </div>
        <div class="item" id="item3" @click="toRoute('Jurassic')">
          <img src="../../images/icons/恐龙.png" alt="" />
          <p>侏罗纪</p>
          <p style="font-size: 14px; color: white">三世纪之中</p>
          <p style="font-size: 14px; color: white">恐龙文明的兴盛时期</p>
        </div>
        <div class="item" id="item4" @click="toRoute('Cretaceous')">
          <img src="../../images/icons/蜥蜴.png" alt="" />
          <p>白垩纪</p>
          <p style="font-size: 14px; color: white">三世纪之末</p>
          <p style="font-size: 14px; color: white">恐龙文明的灭绝时期</p>
        </div>
      </div>
    </div>
    <h1
      style="
        text-align: center;
        margin-top: 1em;
        position: relative;
        z-index: 55;
        font-size: 20px;
      "
      class="liulan"
    >
      浏览科技馆
    </h1>
  </div>
</template>

<script>
export default {
  name: "Block1",
  data() {
    return {
      dataArr: [
        {
          name: "三叠纪",
          info: "三叠纪是2.51亿至2.01亿年前的一个地质时代，它位于二叠纪和侏罗纪之间，是中生代的第一个纪。三叠纪的开始和结束各以一次灭绝事件为标志。",
        },
        {
          name: "侏罗纪",
          info: "侏罗纪是一个地质年代，界于三叠纪和白垩纪之间，约公元前1亿9960万年到1亿4550万年前。",
        },
        {
          name: "白垩纪",
          info: "白垩纪是地质年代中中生代的最后一个纪，开始于1.45亿年前，结束于6600万年前，历经7900万年。",
        },
      ],
    };
  },
  methods: {
    toRoute(name) {
      this.$router.push({
        name: name,
      });
    },
    toBo() {
      window.location.href = "https://baike.sogou.com/v5682917.htm#!";
    },
  },
};
</script>

<style scoped>
/* block1 */
.block1 {
  position: relative;
  padding: 100px 5% 0px 5%;
  background-color: #ffffff;
  background-image: linear-gradient(
    109deg,
    #ffffff 0%,
    #e3effe 50%,
    #ddd6d6 100%
  );
}

.imgbg {
  position: absolute;
  right: 0;
  top: 65px;
}

.Topbox {
  width: 90%;
  height: 100px;
  background-color: #ffffff;
  position: absolute;
  top: -65px;
  left: 5%;
  box-shadow: 0px 0px 0.46rem 0px rgb(0 0 0 / 12%);
  border-radius: 15px;
  display: flex;
  z-index: 10;
}

.Topbox .item {
  width: 33.333333%;
  height: 100px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
  justify-content: center;
}

.Topbox .item:hover {
  transform: scale(1.2);
  background: #00b9f2;
  color: #fff;
  border-radius: 15px;
  z-index: 22;
}

.Topbox .item img {
  height: 50%;
  margin-left: 20px;
}

.Topbox .item .name {
  font-size: 90%;
}

.block1-1 {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #777;
  padding-bottom: 40px;
}

.block11-2-l-tab {
  width: 80%;
}

.block1 .block1-1-l {
  width: 36%;
  height: auto;
  padding-right: 2%;
  position: relative;
}

.block1 .block1-1-l video {
  width: 100%;
  border-radius: 12px;
}

.block1-1-r {
  margin-left: auto;
  width: 64%;
  display: flex;
  justify-content: space-around;
}

.block1-1-r .left {
  width: 25%;
}

.block1-1-r .left h2 {
  color: #333;
  font-family: inherit;
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 30px;
}

.block1-1-r .left > p {
  margin-bottom: 50px;
  color: #666;
  font-size: 18px;
  height: 150px;
}

.block1-1-r .left .year {
  height: 60px;
}

.block1-1-r .right {
  width: 40%;
}

.year {
  display: flex;
}

.year .item {
  width: 33.333333%;
  padding-right: 2%;
  position: relative;
}

.year .item p {
  color: #00b9f2;
  font-size: 18px;
}

.year .item span {
  font-size: 16px;
  color: #888888;
}

.block1-1-r .right h1,
.block1-1-r .right h2 {
  text-align: right;
  color: #333333;
}

.block1-1-r .right h1 {
  font-size: 64px;
  position: relative;
}

.block1-1-r .right h2 {
  font-size: 54px;
  margin-bottom: 60px;
  position: relative;
}

.xiangqing {
  height: 60px;
  text-align: right;
  position: relative;
}

.xiangqing span {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: text-bottom;
  color: #666666;
}

.xiangqing img {
  width: 32px;
  height: 32px;
  margin-left: 20px;
  vertical-align: text-bottom;
}

.left {
  position: relative;
}

.shakeimg {
  width: 30% !important;
  padding-left: 10%;
  animation: shake 6s 0s infinite;
  position: absolute;
  top: 110%;
  left: 0;
}
.shakeimg2 {
  position: absolute;
  top: 8%;
  right: 30%;
  width: 18%;
  animation: shake 12s 0s infinite;
}
.block1-2-l .title {
  width: 100%;
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  position: relative;
}
.title h1 {
  width: 30%;
  font-size: 40px;
  position: relative;
}
.block1-2-l .l-bottom {
  display: flex;
  position: relative;
}

.l-bottom-line {
  width: 7%;
  border-left: 2px solid #888;
}

.linetext {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
}
.linetext h2 {
  margin-top: 25px;
}

.l-bottom-content {
  width: 93%;
}

.l-bottom-content h1 {
  position: relative;
}
.l-bottom-content p {
  width: 50%;
  color: #333;
  margin: 15px 0px;
  text-indent: 2em;
  text-align: justify;
}
.content-img {
  display: flex;
  align-items: center;
}

.content-img .img {
  margin-right: 2%;
  border-radius: 15px;
  overflow: hidden;
}

.content-img .img:nth-child(1) {
  width: 48%;
}
.content-img .img img:hover {
  transform: scale(1.1);
}
.content-img .img:nth-child(2) {
  width: 48%;
}

.content-img .img img {
  width: 100%;
  box-shadow: 0px 6px 3px rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  transition: 0.6s;
}

.block1-2 {
  width: 100%;
  display: flex;
  padding: 30px 0px;
  padding-bottom: 50px;
  position: relative;
}
.block1-2-r {
  width: 20%;
  background-color: #546457;
  border-radius: 15px;
  box-shadow: 2px 4px 1px rgba(0, 0, 0, 0.3);
}

.block1-2-r .item {
  padding: 20px 10% 20px 30%;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #535e55;
  border-width: 80%;
  cursor: pointer;
}

.block1-2-r .item:nth-child(1) {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.block1-2-r .item:nth-child(4) {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.block1-2-r .item:hover {
  background: #505d53;
}

.block1-2-r .item img {
  width: 40%;
}

.block1-2-r .item p {
  color: white;
}
</style>
