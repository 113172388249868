import Vue from "vue";
import App from "./App.vue";

//引入路由
import router from "@/router/router";
import "animate.css";
//引入工具库函数

import {
  Container,
  Carousel,
  CarouselItem,
  Input,
  Select,
  Checkbox,
  Radio,
  Switch,
  DatePicker,
  TimePicker,
  Row,
  Col,
  Form,
  FormItem,
  Option,
  CheckboxGroup,
  RadioGroup,
  Button,
  Upload,
  InputNumber,
  Dialog,
  Drawer,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Loading,
  Tag,
  Empty,
  Card,
  Result,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  Skeleton,
  SkeletonItem,
} from "element-ui";
//#region 使用组件
Vue.use(Container);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Switch);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Option);
Vue.use(CheckboxGroup);
Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Upload);
Vue.use(InputNumber);
Vue.use(Dialog);
Vue.use(Drawer);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Loading);
Vue.use(Tag);
Vue.use(Empty);
Vue.use(Card);
Vue.use(Result);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(MenuItem);
Vue.use(Skeleton);
Vue.use(SkeletonItem);

Vue.prototype.$public = {
  ip: "http://39.98.67.182:8888/",
};
//#endregion

Vue.config.productionTip = false;

new Vue({
  router, //注册路由器
  render: (h) => h(App),
}).$mount("#app");
