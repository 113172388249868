import { render, staticRenderFns } from "./SoftwareIntroduce.vue?vue&type=template&id=7cad4b34&scoped=true&"
import script from "./SoftwareIntroduce.vue?vue&type=script&lang=js&"
export * from "./SoftwareIntroduce.vue?vue&type=script&lang=js&"
import style0 from "./SoftwareIntroduce.vue?vue&type=style&index=0&id=7cad4b34&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cad4b34",
  null
  
)

export default component.exports