<template>
  <div class="Ticket">
    <Header />
    <div class="banner">
      <img src="../images/background/Banner1.jpg" alt="" />
    </div>
    <div class="webAdress">
      <div>
        <img src="../images/icons/401位置.png" alt="" /> 位置：门票预约/{{
          this.$route.meta.nickname
        }}
      </div>
    </div>
    <!-- 主体内容 -->
    <div class="Main-Container">
      <div class="left_nav">
        <div class="nav_outer">
          <div class="navItem" @click="goRoute('AdultTicket')">成人票预约</div>
          <div class="navItem" @click="goRoute('ChildTicket')">儿童票预约</div>
          <div class="navItem" @click="goRoute('CheckTicket')">查询票信息</div>
          <div class="navItem" @click="goRoute('QuitTicket')">申请退票</div>
        </div>
      </div>
      <div class="right_content">
        <router-view></router-view>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header";
import Footer from "@/components/common/Footer";
export default {
  name: "Ticket",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    goRoute(name) {
      if (this.$route.name != name) {
        this.$router.push({
          name: name,
        });
      }
    },
  },
};
</script>

<style scoped>
/* 改变导航样式 */
.nav {
  min-width: 1400px;
  background-color: rgba(0, 0, 0, 0.1);
}
/*  */
.banner {
  position: relative;
  min-width: 1400px;
}
.banner > img {
  width: 100%;
}
.webAdress {
  margin: 30px 0;
}

.webAdress div {
  height: 20px;
  width: 1400px;
  margin: 0 auto;
}
.webAdress div > img {
  height: 20px;
  vertical-align: bottom;
}
.Main-Container {
  width: 1400px;
  min-height: 600px;
  background: #fff;
  margin: 60px auto;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

/* 左侧导航 */
.left_nav {
  width: 190px;
  background: #fff;
  margin-right: 10px;
}
.left_nav > .nav_outer {
  border-top: 4px solid #334d11;
}
.left_nav > .nav_outer > .navItem {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #666666;
  transition: 0.2s;
  cursor: pointer;
}
.left_nav > .nav_outer > .navItem:hover {
  background-color: #334d11;
  color: #fff;
}
.right_content {
  width: 1200px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
}
.right_content > .Title > h1 {
  text-align: center;
}
</style>
